<div class="alert-modal-header csrd-modal-header p-1" id="myModalLabel14">
  <span class="modal-title mb-0 pb-0 ms-2 mb-0 csrd-modal-title">{{ headerTitle }}</span>
  <button
    *ngIf="!isCsrdDeploymentStarted"
    type="button"
    class="btn close"
    data-bs-dismiss="modal"
    aria-label="Close"
    (click)="closeCsrdDeploymentModal()"
  >
    <span aria-hidden="true"><i style="color: white" class="las la-times fs-4"></i></span>
  </button>
</div>

<div *ngIf="!isCsrdDeploymentStarted" class="modal-body">
  <div id="deployInfoMessage" class="p-1">
    <span class="stage-label fw-semibold">
      {{ slocale('You are about complete the assessment of the data points in') + " '" + headerTitle + "'." }}
      <br />
      {{
        slocale(
          ' The system will now add the relevant data categories and data points to your data categories to ensure accurate data
    collection.'
        )
      }}
      <br />
      {{ slocale('Any drafts saved in the data categories will remain unchanged and unaffected by this update.') }}
    </span>
  </div>
</div>

<div *ngIf="isCsrdDeploymentStarted" class="modal-body">
  <div *ngIf="isCsrdDeploymentStarted" id="showDeploymentProgress">
    <div>
      <span class="stage-label fw-semibold" style="margin: 10px">
        {{ slocale('Creating data categories') }}
      </span>
    </div>

    <div class="deployment-item">
      <div style="padding-top: 10px">
        <span class="stage-label">1. {{ slocale('Identify relevant data categories for CSRD') }}</span>
      </div>
      <div style="flex-grow: 1">
        <i
          *ngIf="csrdDeploymentProgressTracker.identify === DEPLOYMENT_STAGE.COMPLETED"
          class="las la-2x la-check ms-1 me-2 my-auto deployment-check-mark"
        ></i>
        <div
          *ngIf="csrdDeploymentProgressTracker.identify === DEPLOYMENT_STAGE.IN_PROGRESS"
          class="ball-pulse loader-primary deployment-loader"
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>

    <div class="deployment-item">
      <div style="padding-top: 10px">
        <span class="stage-label">2. {{ slocale('Assess whether new categories need to be added') }}</span>
      </div>
      <div style="flex-grow: 1">
        <i
          *ngIf="csrdDeploymentProgressTracker.identify === DEPLOYMENT_STAGE.COMPLETED"
          class="las la-2x la-check ms-1 me-2 my-auto deployment-check-mark"
        ></i>
      </div>
    </div>

    <div class="deployment-item">
      <div style="padding-top: 10px">
        <span class="stage-label">3. {{ slocale('Create backup of potential data categories and data points') }}</span>
      </div>
      <div style="flex-grow: 1">
        <i
          *ngIf="csrdDeploymentProgressTracker.draft === DEPLOYMENT_STAGE.COMPLETED"
          class="las la-2x la-check ms-1 me-2 my-auto deployment-check-mark"
        ></i>
        <div
          *ngIf="csrdDeploymentProgressTracker.draft === DEPLOYMENT_STAGE.IN_PROGRESS"
          class="ball-pulse loader-primary deployment-loader"
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>

    <div class="deployment-item">
      <div style="padding-top: 10px">
        <span class="stage-label">4. {{ slocale('Create relevant data categories and data points') }}</span>
      </div>
      <div style="flex-grow: 1">
        <i
          *ngIf="csrdDeploymentProgressTracker.draft === DEPLOYMENT_STAGE.COMPLETED"
          class="las la-2x la-check ms-1 me-2 my-auto deployment-check-mark"
        ></i>
      </div>
    </div>

    <div>
      <div class="deployment-item">
        <div style="padding-top: 10px">
          <span class="stage-label">5. {{ slocale('Save updated data categories') }}</span>
        </div>
        <div style="flex-grow: 1">
          <i
            *ngIf="csrdDeploymentProgressTracker.save === DEPLOYMENT_STAGE.COMPLETED"
            class="las la-2x la-check ms-1 me-2 my-auto deployment-check-mark"
          ></i>
          <div
            *ngIf="!isCsrdDeploymentFailed && csrdDeploymentProgressTracker.save === DEPLOYMENT_STAGE.IN_PROGRESS"
            class="ball-pulse loader-primary deployment-loader"
          >
            <div></div>
            <div></div>
            <div></div>
          </div>
          <i
            *ngIf="csrdDeploymentProgressTracker.save !== DEPLOYMENT_STAGE.COMPLETED && isCsrdDeploymentFailed"
            class="info las la-info-circle warning-icon"
          ></i>
        </div>
      </div>
      <div
        *ngIf="csrdDeploymentProgressTracker.save !== DEPLOYMENT_STAGE.COMPLETED && isCsrdDeploymentFailed"
        class="error-message p-3"
      >
        <p>
          {{
            slocale(
              "An error occurred and the system couldn't complete the changes, your changes have been saved as draft."
            )
          }}
        </p>
      </div>
    </div>

    <div *ngIf="!isCsrdDeploymentFailed" class="deployment-item">
      <div style="padding-top: 10px">
        <span class="stage-label">6. {{ slocale('Restore potential data categories draft') }}</span>
      </div>
      <div style="flex-grow: 1">
        <i
          *ngIf="csrdDeploymentProgressTracker.restore === DEPLOYMENT_STAGE.COMPLETED"
          class="las la-2x la-check ms-1 me-2 my-auto deployment-check-mark"
        ></i>
        <div
          *ngIf="!isCsrdDeploymentFailed && csrdDeploymentProgressTracker.restore === DEPLOYMENT_STAGE.IN_PROGRESS"
          class="ball-pulse loader-primary deployment-loader"
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!isCsrdDeploymentStarted || isCsrdDeploymentFailed" class="modal-footer">
  <ng-container>
    <button
      *ngIf="!isCsrdDeploymentStarted"
      type="button"
      class="btn secondary-button custom-button text-white"
      (click)="closeCsrdDeploymentModal()"
    >
      {{ slocale('Cancel') }}
    </button>
    <button
      [disabled]="isCsrdDeploymentStarted"
      *ngIf="!isCsrdDeploymentStarted"
      type="button"
      class="btn primary-button text-white"
      (click)="confirmCsrdTaxonomyDeployment()"
    >
      {{ slocale('Confirm & Create') }}
    </button>
    <button
      *ngIf="isCsrdDeploymentFailed"
      type="button"
      class="btn primary-button text-white"
      (click)="closeCsrdDeploymentModal()"
      style="min-width: 120px"
    >
      {{ slocale('Ok') }}
    </button>
  </ng-container>
</div>
