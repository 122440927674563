<div class="drDatapointsEditComponent">
  <!-- HEADER -->
  <div class="header">
    {{ slocale('Assessing the data points') }}
  </div>
  <!-- BODY -->
  <div class="body">
    <div class="infoText">
      <div class="review">
        {{ slocale('Review the details of the Disclosure Requirement and each data point.') }}
      </div>
      <div class="determine" *ngIf="activeEsrs.code != 'ESRS 2'">
        {{
          slocale(
            'Derermine the materiality of each datapoint. If a datapoint can be omitted from data collection, decide whether
        to do so.'
          )
        }}
      </div>
    </div>
    <div class="esrsCard">
      <div class="esrsName">{{ getActiveDrTitle() }}</div>
      <div class="esrsDescription">
        <span class="bold">{{ slocale('Description : ') }}</span>
        {{ activeDr.description }}
      </div>
      <div class="esrsObjective">
        <span class="bold">{{ slocale('Objective : ') }}</span>
        {{ activeDr.objective }}
      </div>
      <small>
        {{ slocale('See guidance') }}
        <i class="la la-arrows-alt"></i>
      </small>
    </div>

    <div class="legend">
      <div class="label">{{ slocale('Legend : ') }}</div>
      <div class="itemList">
        <div class="item phaseIn">
          <i class="la la-clock icon"></i>
          <div class="name">
            {{ slocale('Phase-in or effective date') }}
          </div>
        </div>
        <div class="item mayDisclose">
          <div class="info">
            <i class="la la-question icon" data-bs-toggle="dropdown"></i>
            <div class="dropdown-menu popover">
              {{
                slocale(
                  'Data points formulated using “may disclose” in the legislation indicate a voluntary disclosure to
              encourage good practice. Selecting the “omit” option means the data for this ESRS point will not be
              collected.'
                )
              }}
            </div>
          </div>
          <div class="name">
            {{ slocale('May disclose') }}
          </div>
        </div>
        <div class="item conditional">
          <i class="las la-code-branch icon"></i>
          <div class="name">
            {{ slocale('Conditional') }}
          </div>
        </div>
        <div class="item showQuestions">
          <i class="la la-eye icon"></i>
          <div class="name">
            {{ slocale('Show data collection questions') }}
          </div>
        </div>
        <div class="item questionsEdited">
          <i class="la la-eye icon"></i>
          <div class="name">
            {{ slocale('Questions are edited') }}
          </div>
        </div>
        <div class="item showMoreInfo">
          <i class="la la-info-circle icon"></i>
          <div class="name">
            {{ slocale('Show more information') }}
          </div>
        </div>
        <div class="item addComment">
          <i class="las la-comment-medical icon"></i>
          <div class="name">
            {{ slocale('Add internal comment') }}
          </div>
        </div>
        <div class="item showComment">
          <i class="las la-comment icon"></i>
          <div class="name">
            {{ slocale('Show internal comment') }}
          </div>
        </div>
      </div>
    </div>

    <div class="datapoints">
      <div class="datapointListHeader">
        <div class="lhs">
          <div class="title">
            {{ slocale('ESRS data point') }}
          </div>
          <div class="switchers">
            <div class="showAllInfo switcherBox">
              <div class="switch" id="showAllInfoSwitcher" (click)="toggleSwitch('showAllInfo')">
                <input type="checkbox" [checked]="showAllInfo" />
                <span class="slider round"></span>
              </div>
              <label for="showAllInfoSwitcher">{{ slocale('Show all info and descriptions') }}</label>
            </div>
          </div>
        </div>
        <div class="rhs">
          <div class="title">
            {{ slocale('Settings') }}
          </div>
        </div>
      </div>
      <div class="datapointList">
        <ng-container *ngFor="let datapoint of csrdDatapoints">
          <div class="datapoint">
            <div class="lhs">
              <div class="title">
                <div class="status phaseIn" *ngIf="datapoint.phaseIn">
                  <i class="la la-clock icon"></i>
                </div>
                <div class="status mayDisclose" *ngIf="datapoint.isVoluntary">
                  <i class="la la-question icon"></i>
                </div>
                <div class="status conditional" *ngIf="datapoint.displayConditionIcon">
                  <i class="las la-code-branch icon"></i>
                </div>
                <div class="label">{{ datapoint.id }} {{ datapoint.name }}</div>

                <div class="controls">
                  <div class="status questions" *ngIf="false">
                    <i class="la la-eye icon"></i>
                  </div>
                  <div
                    class="status descriptionIconBox"
                    [class.open]="datapoint.showMoreInfo"
                    (click)="toggleShowMoreInfo(datapoint)"
                  >
                    <i class="la la-info-circle icon"></i>
                  </div>
                </div>
              </div>

              <ng-container *ngIf="datapoint.showMoreInfo">
                <div class="info phaseIn" *ngIf="datapoint.phaseIn">
                  <div class="body">
                    <span class="label">{{ slocale('Phase in information : ') }}</span>
                    {{ datapoint.phaseIn }}
                  </div>
                </div>
                <div class="info conditional" *ngIf="datapoint.conditional">
                  <div class="body">
                    <span class="label">{{ slocale('Conditional information : ') }}</span>
                    {{ datapoint.conditional }}
                  </div>
                </div>
                <div class="info legislation" *ngIf="datapoint.description">
                  <div class="header">{{ slocale('Description from the standards') }}</div>
                  <div class="body" [innerHTML]="datapoint.description"></div>
                  <div class="footer">
                    <div class="seeGuidance" (click)="openCsrdDatapointTab(datapoint)">
                      <div class="label">{{ slocale('See guidance') }}</div>
                      <i class="las la-external-link-alt"></i>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="rhs">
              <div class="materiality">
                <div class="dpBadge mandatory" *ngIf="datapoint.isMandatory">Mandatory</div>
                <div class="dropdown materialitySeletor" data-bs-toggle="dropdown" *ngIf="!datapoint.isMandatory">
                  <div class="dropdownStatus">
                    <div class="selectedMateriality material" *ngIf="datapoint.isMaterial">
                      {{ slocale('Material') }}
                    </div>
                    <div class="selectedMateriality notMaterial" *ngIf="!datapoint.isMaterial">
                      {{ slocale('Not Material') }}
                    </div>
                    <div class="dropdown-toggle"></div>
                  </div>

                  <div class="dropdown-menu">
                    <div
                      class="dropdown-item materialityOption material"
                      *ngIf="!datapoint.isMaterial"
                      (click)="toggleMateriality(datapoint)"
                    >
                      <span>{{ slocale('Material') }}</span>
                    </div>
                    <div
                      class="dropdown-item materialityOption notMaterial"
                      *ngIf="datapoint.isMaterial"
                      (click)="toggleMateriality(datapoint)"
                    >
                      <span>{{ slocale('Not material') }}</span>
                    </div>
                  </div>
                </div>
                <i
                  class="las la-comment-medical addComment control"
                  [class.open]="addingComment[datapoint.id]"
                  (click)="toggleAddingComment(datapoint)"
                  *ngIf="!datapoint.comment || (datapoint.comment && addingComment[datapoint.id])"
                ></i>
                <i
                  class="las la-comment showComment control"
                  [class.open]="datapoint.showComment"
                  (click)="toggleShowComment(datapoint)"
                  *ngIf="datapoint.comment && !addingComment[datapoint.id]"
                ></i>
              </div>
              <div
                class="comment"
                *ngIf="
                  (!datapoint.comment && addingComment[datapoint.id]) ||
                  (datapoint.comment && datapoint.showComment) ||
                  (datapoint.comment && addingComment[datapoint.id])
                "
              >
                <textarea
                  [(ngModel)]="datapoint.comment"
                  class="datapointCommentInput"
                  (keypress)="adjustCommentHeight($event.target)"
                  (input)="adjustCommentHeight($event.target)"
                  (change)="adjustCommentHeight($event.target)"
                ></textarea>
                <i
                  class="las la-trash-alt control"
                  (click)="startDeleteComment(datapoint)"
                  *ngIf="datapoint.comment"
                ></i>
              </div>
              <div class="omit" *ngIf="datapoint.isMaterial && datapoint.canOmit">
                <div class="helpText">
                  {{
                    slocale(
                      'This data point can potentially be omitted from data collection. Read the available information
                  carefully before making a decision.'
                    )
                  }}
                </div>
                <div class="switcherRow">
                  <div class="switcher">
                    <div class="switch" id="omitSwitcher" (click)="startOmitDatapoint(datapoint)">
                      <input type="checkbox" [checked]="datapoint.isOmitted" />
                      <span class="slider round"></span>
                    </div>
                    <label for="omitSwitcher">{{ slocale('Omit from data collection') }}</label>
                  </div>
                  <div class="info">
                    <i class="la la-info-circle icon" data-bs-toggle="dropdown"></i>
                    <div class="dropdown-menu popover">
                      {{
                        slocale(
                          'Data points formulated using “may disclose” in the legislation indicate a voluntary disclosure to
                      encourage good practice. Selecting the “omit” option means the data for this ESRS point will not
                      be collected.'
                        )
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="collectVoluntarily" *ngIf="!datapoint.isMaterial">
                <div class="switcherRow">
                  <div class="switcher">
                    <div class="switch" id="collectVoluntarilySwitcher" (click)="toggleCollectVoluntarily(datapoint)">
                      <input type="checkbox" [checked]="datapoint.collectVoluntarily" />
                      <span class="slider round"></span>
                    </div>
                    <label for="collectVoluntarilySwitcher">{{ slocale('Collect data voluntarily') }}</label>
                  </div>
                  <div class="info">
                    <i class="la la-info-circle icon" data-bs-toggle="dropdown"></i>
                    <div class="dropdown-menu popover">
                      {{
                        slocale(
                          "Data points labeled 'not material' are not collected by default, but you can opt to collect them
                      voluntarily. If you do so, they will be marked as voluntarily collected data in subsequent steps."
                        )
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <!-- FOOTER -->
  <div class="footer">
    <div *ngIf="savingAssessment" class="ball-pulse loader-primary">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
    <ng-container *ngIf="!savingAssessment">
      <button class="btn secondary-button custom-button" (click)="startCancelProcess()">
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
      <button class="btn tertiary-button custom-button" (click)="startSaveAsDraft()">
        {{ slocale('Save as draft') }}
      </button>
      <button class="btn primary-button custom-button" (click)="openCsrdDeploymentConfirmationModal()">
        {{ locale('locale_key.general.buttons.confirm') }}
      </button>
    </ng-container>
  </div>
</div>

<ng-template #omitDataModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale('Omit data point in reporting') }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>

  <div class="modal-body">
    <!-- <p class="ps-2">
        {{
        slocale(
        "You have switched on the General phase in information toggle for all phase in data points. Turning off for this data point will keep the state of other currently omitted data points and show “Customized” text next to the General
        phase in information toggle."
        )
        }}
      </p> -->
    <p class="ps-2">
      {{ slocale('Are you sure you want to turn this toggle on?') }}
    </p>
  </div>

  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
            <div style="background-color: var(--main-dark-color1)"></div>
            <div style="background-color: var(--main-dark-color1)"></div>
            <div style="background-color: var(--main-dark-color1)"></div>
          </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button custom-button text-white" (click)="cancelOmitDatapoint()">
        {{ slocale('Cancel') }}
      </button>
      <button type="button" class="btn primary-button text-white" (click)="confirmOmitDatapoint()">
        {{ slocale('Omit data point') }}
      </button>
    </ng-container>
  </div>
</ng-template>

<ng-template #deleteCommentModal>
  <div class="drDatapointsEditComponent delete_comment_modal" style="padding: 16px">
    <div class="header">
      <div class="title">
        {{ slocale('Delete internal comment') }}
      </div>
      <div class="close_control">
        <i class="las la-times" (click)="cancelDeleteComment()"></i>
      </div>
    </div>
    <div class="body">
      <div class="message">
        {{
          slocale(
            "You're about to delete this internal comment. Once deleted, you won't be able to restore it, so you'll have to
        re-enter it if needed."
          )
        }}
      </div>
      <div class="message">
        {{ slocale('Are you sure you want to delete this comment?') }}
      </div>
    </div>
    <div class="footer">
      <div class="controls">
        <div class="cancel_control" (click)="cancelDeleteComment()">
          {{ locale('locale_key.general.buttons.cancel') }}
        </div>
        <div class="delete_control" (click)="confirmDeleteComment()">
          {{ locale('locale_key.general.buttons.delete') }}
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #saveBeforeLeavingModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale('Save before leaving') }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="dontLeavePage()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>

  <div class="modal-body">
    <!-- <p class="ps-2">
        {{
        slocale(
        "You have switched on the General phase in information toggle for all phase in data points. Turning off for this data point will keep the state of other currently omitted data points and show “Customized” text next to the General
        phase in information toggle."
        )
        }}
      </p> -->
    <p class="ps-2">
      {{
        slocale(
          "If you leave this page your changes won't be saved. To save changes return to the page and select the save option
      you want."
        )
      }}
    </p>
  </div>

  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
            <div style="background-color: var(--main-dark-color1)"></div>
            <div style="background-color: var(--main-dark-color1)"></div>
            <div style="background-color: var(--main-dark-color1)"></div>
          </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button custom-button text-white" (click)="leavePage()">
        {{ slocale("Don't save") }}
      </button>
      <button type="button" class="btn primary-button text-white" (click)="dontLeavePage()">
        {{ slocale('Return to page') }}
      </button>
    </ng-container>
  </div>
</ng-template>

<ng-template #cancelProcessModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale('Cancel Process') }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="dontCancelProcess()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>

  <div class="modal-body">
    <!-- <p class="ps-2">
        {{
        slocale(
        "You have switched on the General phase in information toggle for all phase in data points. Turning off for this data point will keep the state of other currently omitted data points and show “Customized” text next to the General
        phase in information toggle."
        )
        }}
      </p> -->
    <p class="ps-2">
      {{
        slocale(
          'Are you sure you want to cancel? Your changes in this assessment will be undone and not saved. To keep your
      progress, return to the page and use the "Save as draft".'
        )
      }}
    </p>
  </div>

  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
            <div style="background-color: var(--main-dark-color1)"></div>
            <div style="background-color: var(--main-dark-color1)"></div>
            <div style="background-color: var(--main-dark-color1)"></div>
          </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button text-white" (click)="dontCancelProcess()">
        {{ slocale('Return to page') }}
      </button>
      <button type="button" class="btn primary-button text-white" (click)="cancelProcess()">
        {{ slocale('Confirm') }}
      </button>
    </ng-container>
  </div>
</ng-template>

<ng-template #saveAsDraftModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale('Save as draft') }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="cancelSaveAsDraft()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>

  <div class="modal-body">
    <ng-container *ngIf="!activeDr.hasDraft">
      <p class="ps-2">
        {{ slocale('You are about to save a draft for the first time. Are you sure you want to save?') }}
      </p>
    </ng-container>
    <ng-container *ngIf="activeDr.hasDraft">
      <p class="ps-2">
        {{
          slocale(
            'You are about to save a draft. This action will overwrite any previously saved draft version. Are you sure you
        want to overwrite it?'
          )
        }}
      </p>
    </ng-container>
  </div>

  <div class="modal-footer" style="border-top: none">
    <div *ngIf="savingAssessment" class="ball-pulse loader-primary">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
    <ng-container *ngIf="!savingAssessment">
      <button type="button" class="btn secondary-button custom-button" (click)="cancelSaveAsDraft()">
        {{ slocale('Cancel') }}
      </button>
      <button type="button" class="btn primary-button text-white" (click)="saveAsDraft()">
        {{ slocale('Save as draft') }}
      </button>
    </ng-container>
  </div>
</ng-template>

<!-- MODALS -->

<ng-template #confirmDeployCsrdTaxonomyModal>
  <app-csrd-taxonomy-deployment-modal
    [headerTitle]="getActiveDrTitle()"
    (csrdDeploymentCompleted)="saveAssessment(); closeCsrdDeploymentModal()"
    (csrdDeploymentCancelled)="closeCsrdDeploymentModal()"
  ></app-csrd-taxonomy-deployment-modal>
</ng-template>
