<div class="flex-fill w-100">
  <div class="d-flex h-100 flex-column">
    <div class="card" style="max-width: 100vw !important">
      <div class="card-header p-0" style="height: var(--app-header-footer-height)">
        <ng-container *ngIf="screenSize.isLargeSize() || screenSize.isXLargeSize()">
          <div class="col-lg-12">
            <div class="d-flex flex-row">
              <div class="col-lg-12 col-md-12 mt-0 pt-0 m-auto d-flex flex-row justify-content-center">
                <div class="col-lg-3 col-md-3 mt-0 pt-0 mx-0 px-0">
                  <div class="d-flex flex-row h-100 text-start justify-content-center align-items-center">
                    <div class="circle1" [ngClass]="{ 'active-circle1': activeWizardPageNumber >= 1 }">1</div>
                    <h6 class="my-auto ms-2">{{ slocale('Add title & description') }}</h6>
                    <span style="font-size: 1.5rem"><i class="las la-angle-right"></i></span>
                  </div>
                </div>
                <div class="col-lg-3 col-md-12 text-start mx-0 px-0">
                  <div class="d-flex flex-row h-100 justify-content-center align-items-center">
                    <div class="circle1" [ngClass]="{ 'active-circle1': activeWizardPageNumber >= 2 }">2</div>
                    <h6 class="my-auto ms-2">{{ slocale('Create questions') }}</h6>
                    <span style="font-size: 1.5rem"><i class="las la-angle-right"></i></span>
                  </div>
                </div>

                <div class="col-lg-3 col-md-12 text-start mx-0 px-0">
                  <div class="d-flex flex-row h-100 justify-content-center align-items-center">
                    <div class="circle1" [ngClass]="{ 'active-circle1': activeWizardPageNumber >= 3 }">3</div>
                    <h6 class="my-auto ms-2">{{ slocale('Define submission details') }}</h6>
                  </div>
                </div>
                <div class="col-lg-3 col-md-12 text-end mx-0 px-0" *ngIf="activeWizardPageNumber === 2">
                  <div class="d-flex flex-row h-100 justify-content-end align-items-center">
                    <ng-container *ngTemplateOutlet="questHeader"></ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="screenSize.isMediumSize() || screenSize.isSmallerSize() || screenSize.isXSmallSize()">
          <div class="d-flex flex-row justify-content-center mb-1 pt-3">
            <div class="d-block text-center">
              <div
                class="col stepper text-center"
                *ngFor="let step of wizardSteps; let i = index"
                [class.active-stepper]="activeWizardPageNumber === i + 1 || step.completed"
              ></div>
            </div>
          </div>
          <div class="d-flex flex-row justify-content-center mb-1">
            <h6 class="text-center m-0">
              {{ wizardSteps[activeWizardPageNumber - 1].title }} ({{ activeWizardPageNumber }}/{{
                wizardSteps.length
              }})
            </h6>
          </div>
        </ng-container>
      </div>
      <div
        class="card-body mt-0 pt-0"
        ngStyle=""
        [ngStyle]="{
          height: isCSRDQuestionnaire
            ? 'var(--page-content-minus-4-page-header-footer)'
            : 'var(--page-content-minus-3-page-header-footer)'
        }"
        style="overflow-y: scroll !important; border-bottom: none; max-width: 100vw !important"
      >
        <!-- <hr class="mt-0 pt-0 mx-0 px-0" /> -->
        <!-- <ng-container *ngIf="isCSRDQuestionnaire && activeWizardPageNumber == 2">
          <div class="d-flex flex-row">
            <p class="bold mb-3 ms-1">{{slocale('Review the outgoing questionnaire and, if needed, add additional descriptions or request proof documents for the questions.')}}</p>
          </div>
          <div class="d-flex flex-row">
            <div class="accordion w-100 mb-3" id="accordionExample">
              <div class="accordion-item w-100 esrs-accordion">
                <h2 class="accordion-header ">
                  <button class="accordion-button bg-comp-grey" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                   {{slocale('E3-4 - Water consumption')}}
                  </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                     
                  </div>
                </div>
              </div>        
            </div>
          </div>
          <div class="d-flex flex-row mb-3">
            <label class="bold me-2 ms-1">{{ slocale("Legend : ") }}</label>
            <i class="la la-info-circle dropdown-toggle info-popover-arrow icon me-1" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="position: relative;margin-top: 5px;"></i> <label class="me-2 text-muted fs-6">{{ slocale("Show more information") }}</label>
            <div class="dropdown-menu arrow p-2 info-popover">
              <p>{{locale('Some Info')}}</p>
            </div>
            <i class="las la-comment icon dropdown-toggle info-popover-arrow me-1" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="position: relative;margin-top: 5px;"></i> <label class=" text-muted fs-6">{{ slocale("Show internal comment") }}</label>
            <div class="dropdown-menu arrow p-2 info-popover">
              <p>{{locale('Some Info')}}</p>
            </div>      
          </div>
        </ng-container> -->

        <div *ngIf="activeWizardPageNumber == 1">
          <h3>{{ locale('locale_key.pages.data_request.create.title_desc') }}</h3>
          <div class="row ms-4 mt-1" style="max-width: 40%" [formGroup]="dataRequestDetailsForm">
            <div class="col-12">
              <div class="form-group">
                <label for="title">{{ locale('locale_key.pages.data_request.request.title') }}*</label>
                <input
                  type="text"
                  class="form-control"
                  id="title"
                  placeholder="{{ locale('locale_key.pages.data_request.create.title.placeholder') }}"
                  formControlName="title"
                  [value]="getTitle() || ''"
                  [ngClass]="{
                    'is-invalid':
                      dataRequestDetailsForm.get('title').errors && dataRequestDetailsForm.get('title').touched
                  }"
                  data-test="request-title-field"
                  (input)="onTitleChange($event)"
                />
                <div
                  *ngIf="dataRequestDetailsForm.get('title').errors && dataRequestDetailsForm.get('title').touched"
                  class="invalid-feedback"
                >
                  <div *ngIf="dataRequestDetailsForm.get('title').errors.required">
                    {{ locale('locale_key.general.validation_message.request_title_required') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 mt-3">
              <div class="form-group">
                <label for="title">{{ locale('locale_key.pages.data_request.create.desc.title') }}</label>
                <textarea
                  type="text"
                  class="form-control"
                  id="description"
                  placeholder="{{ locale('locale_key.pages.data_request.create.desc.placeholder') }}"
                  formControlName="desc"
                  style="min-height: 250px"
                  (keyup)="autoGrowDescriptionTextArea($event)"
                  data-test="request-description"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="activeWizardPageNumber == 2">
          <div *ngIf="!isQuestionnaireInPreviewMode">
            <div *ngIf="!isCSRDQuestionnaire">
              <edit-questionnaire
                [sections]="sections"
                (deletedSections)="sections = $event"
                [searchText]="searchText"
                [showHeader]="!isCSRDQuestionnaire"
                [allExpanded]="allExpanded"
                [expandCollapseEvent]="expandCollapseEvent"
                (searchTextChange)="filterQuestions()"
                (accordionStateChange)="onAccordionStateChange($event)"
                [isCSRDQuestionnaire]="isCSRDQuestionnaire"
              ></edit-questionnaire>
            </div>
          </div>
          <!--Questionnaire preview page starts-->
          <div *ngIf="isQuestionnaireInPreviewMode">
            <questionnaire-preview
              [questionnaire]="requestForPreview.questionnaire"
              [requestTitle]="dataRequestDetailsForm.controls.title.value"
              [searchText]="searchText"
              [showHeader]="false"
              [allExpanded]="allExpanded"
              [expandCollapseEvent]="expandCollapseEvent"
              (editQuestionnaire)="editQuestionnaireFromPreview()"
              (accordionStateChange)="onAccordionStateChange($event)"
              [(editMapping)]="editMapping"
            ></questionnaire-preview>
            <!-- <div class="d-flex flex-row mt-3 mx-1" *ngIf="isCSRDQuestionnaire">
                    <div class="accordion w-100 mb-3" id="accordionExample1">
                      <div class="accordion-item w-100">
                        <h2 class="accordion-header">
                          <button class="accordion-button bg-light-gray d-flex flex-row justify-content-between align-items-center info-popover-arrow" type="button" 
                                  (click)="toggleAccordion('toggleText1', 'collapseOne')" 
                                  [attr.aria-expanded]="isOpen1" 
                                  aria-controls="collapseOne">
                            <span class="flex-grow-1 dark-text">{{ slocale('These data points will be automatically calculated from collected inputs.') }}</span>
                            <span class="ms-auto dark-text me-3" id="toggleText1">{{ isOpen1 ? 'Hide' : 'Show' }}</span>
                          </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse" [ngClass]="{'show': isOpen1}" data-bs-parent="#accordionExample1">
                          <div class="accordion-body bg-comp-grey p-3">
                            <div class="d-flex flex-column w-100">
                              <div class="d-flex flex-row w-100 align-items-center thin-border p-3 bg-white">
                                <h6 class="m-0 my-auto">{{ slocale('ESRS data point name 1') }}</h6>
                                <i class="la la-info-circle dropdown-toggle info-popover-arrow fs-5 ms-1" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ></i>
                                <div class="dropdown-menu arrow p-2 info-popover">
                                  <p class="dark-text">{{ slocale("The undertaking shall disclose the following information:for consolidated sustainability statements:a confirmation that the scope of consolidation is the same as for the financial statements, or, where applicable, a declaration that the reporting undertaking is not required to draw-up financial statements or that the reporting undertaking is preparing consolidated sustainability reporting pursuant to Article 48i of Directive 2013/34/EU;") }}</p>
                                  <a  href="#" class="text-muted underline mt-3">{{slocale('See guidance')}} <i class="la las la-external-link-alt fs-5" ></i></a>
                                </div>
                              </div>
                              <div class="d-flex flex-row w-100 align-items-center thin-border p-3 bg-white">
                                <h6 class="m-0 my-auto">{{ slocale('ESRS data point name 2') }}</h6>
                                <i class="la la-info-circle dropdown-toggle info-popover-arrow fs-5 ms-1" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ></i>
                                <div class="dropdown-menu arrow p-2 info-popover">
                                  <p class="dark-text">{{ slocale("The undertaking shall disclose the following information:for consolidated sustainability statements:a confirmation that the scope of consolidation is the same as for the financial statements, or, where applicable, a declaration that the reporting undertaking is not required to draw-up financial statements or that the reporting undertaking is preparing consolidated sustainability reporting pursuant to Article 48i of Directive 2013/34/EU;") }}</p>
                                  <a  href="#" class="text-muted underline mt-3">{{slocale('See guidance')}} <i class="la las la-external-link-alt fs-5" ></i></a>
                                </div>
                              </div>
                              <div class="d-flex flex-row w-100 align-items-center thin-border p-3 bg-white">
                                <h6 class="m-0 my-auto">{{ slocale('ESRS data point name 3') }}</h6>
                                <i class="la la-info-circle dropdown-toggle info-popover-arrow fs-5 ms-1" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ></i>
                                <div class="dropdown-menu arrow p-2 info-popover">
                                  <p class="dark-text">{{ slocale("The undertaking shall disclose the following information:for consolidated sustainability statements:a confirmation that the scope of consolidation is the same as for the financial statements, or, where applicable, a declaration that the reporting undertaking is not required to draw-up financial statements or that the reporting undertaking is preparing consolidated sustainability reporting pursuant to Article 48i of Directive 2013/34/EU;") }}</p>
                                  <a  href="#" class="text-muted underline mt-3">{{slocale('See guidance')}} <i class="la las la-external-link-alt fs-5" ></i></a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
          </div>
          <!--Questionnaire preview page ends-->
        </div>
        <div *ngIf="activeWizardPageNumber == 3">
          <ng-container>
            <submission-details-questionnaire
              [form3]="submissionForm"
              [recurringRequest]="false"
              [allDataOwners]="allDataOwners"
              [nextDeadline]="nextDeadline"
              (deadlineChanged)="nextDeadline = $event"
            ></submission-details-questionnaire>
          </ng-container>
        </div>
      </div>
      <div
        id="card-footer"
        class="card-footer float-end mt-auto text-end quest-footer"
        style="max-width: 100vw; height: var(--app-header-footer-height)"
      >
        <span>
          <div
            class="connectedActionBtn p-1 d-flex flex-row justify-content-start align-items-center"
            *ngIf="isConnectedAction()"
          >
            <div class="icon-circle-dark d-flex align-items-center" style="max-width: 40px !important">
              <span class="text-white">{{ categoryService.totalChanges() }}</span>
            </div>
            <span>
              {{ locale('locale_key.general.connected_actions.title') }}
            </span>
            <i class="las la-external-link-square-alt" (click)="displayService.openConnectedActionsTab()"></i>
          </div>
        </span>
        <div class="ball-pulse loader-primary" *ngIf="loadingInProgress">
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
        </div>
        <ng-container *ngIf="!loadingInProgress">
          <button
            type="button"
            class="btn mx-1 p-1 tertiary-button custom-button"
            (click)="closeQuestionnaire()"
            *ngIf="!isQuestionnaireInPreviewMode && !isCSRDQuestionnaire"
          >
            {{ locale('locale_key.general.buttons.cancel') }}
          </button>
          <button
            type="button"
            class="btn mx-1 p-1 tertiary-button custom-button"
            (click)="closePreview()"
            *ngIf="isQuestionnaireInPreviewMode && !isCSRDQuestionnaire"
          >
            {{ locale('locale_key.general.buttons.cancel') }}
          </button>
          <button
            type="button"
            class="btn mx-1 p-1 secondary-button custom-button"
            *ngIf="!isCSRDQuestionnaire && activeWizardPageNumber != 1"
            (click)="isSaveAsDraft = true; openConnectedActionsModal()"
          >
            {{ locale('locale_key.button.save_as_draft') }}
          </button>
          <button
            type="button"
            class="btn mx-1 p-1 tertiary-button custom-button"
            *ngIf="activeWizardPageNumber != 1"
            (click)="previousWizardPage()"
          >
            {{ locale('locale_key.general.buttons.previous') }}
          </button>
          <button
            type="button"
            class="btn mx-1 p-1 primary-button custom-button"
            *ngIf="activeWizardPageNumber != 3"
            (click)="nextWizardPage()"
            [disabled]="!dataRequestDetailsForm.get('title').valid"
          >
            {{ locale('locale_key.general.buttons.next') }}
          </button>
          <button
            type="button"
            class="btn mx-1 p-1 primary-button custom-button"
            *ngIf="activeWizardPageNumber == 3"
            (click)="isSaveAsDraft = false; openConnectedActionsModal()"
          >
            {{ locale('locale_key.pages.data_request.create_wizard.button.send_request') }}
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #confirmSendRequest>
  <div class="modal-header" style="border-bottom: none">
    <h4 class="modal-title">{{ locale('locale_key.pages.data_request.send_request_confirmation_modal.title') }}</h4>
    <button type="button" class="btn close" *ngIf="!loadingInProgress" (click)="closeModal()" style="cursor: pointer">
      <span aria-hidden="true" class="black" style="font-size: 2rem; font-weight: 300">&times;</span>
    </button>
  </div>
  <div class="m-2 px-2">
    <p class="m-0">{{ locale('locale_key.pages.data_request.send_request_confirmation_modal.desc') }}</p>
    <p *ngIf="isFromDraft">{{ locale('locale_key.data_request.send_request_modal.draft_desc') }}</p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <ng-container *ngIf="!loadingInProgress">
      <button type="button" class="btn tertiary-button custom-button" (click)="closeModal()">
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
      <button
        type="button"
        class="btn px-0 secondary-button custom-button"
        *ngIf="isFromDraft"
        (click)="sendRequest(true, true)"
      >
        {{ locale('locale_key.general.buttons.send_and_keep') }}
      </button>
      <button type="button" class="btn primary-button custom-button" *ngIf="isFromDraft" (click)="sendRequest(true)">
        {{ locale('locale_key.general.buttons.send_and_remove') }}
      </button>
      <button type="button" class="btn primary-button custom-button" *ngIf="!isFromDraft" (click)="sendRequest(true)">
        {{ locale('locale_key.general.buttons.send') }}
      </button>
    </ng-container>
    <div class="ball-pulse loader-primary" *ngIf="loadingInProgress">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
  </div>
</ng-template>
<ng-template #confirmSaveAsDraft>
  <div class="modal-header" style="border-bottom: none">
    <h4 class="modal-title">{{ locale('locale_key.button.save_as_draft') }}</h4>
    <button type="button" class="btn close" *ngIf="!loadingInProgress" (click)="closeModal()" style="cursor: pointer">
      <span aria-hidden="true" class="black" style="font-size: 2rem; font-weight: 300">&times;</span>
    </button>
  </div>
  <div class="m-2 px-2">
    <p>
      {{
        locale('locale_key.data_request.save_draft_modal.description_request_name', {
          request_name: dataRequestDetailsForm.controls.title.value
        })
      }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <ng-container *ngIf="!loadingInProgress">
      <button type="button" class="btn tertiary-button custom-button" (click)="closeModal()">
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
      <button type="button" class="btn secondary-button custom-button" (click)="saveDraft(true)">
        {{ locale('locale_key.general.buttons.save_as_new') }}
      </button>
      <button
        type="button"
        class="btn primary-button custom-button px-0"
        *ngIf="isDraftExists()"
        (click)="saveDraft(false)"
      >
        {{ locale('locale_key.general.buttons.replace_existing') }}
      </button>
    </ng-container>
    <div class="ball-pulse loader-primary" *ngIf="loadingInProgress">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
  </div>
</ng-template>
<ng-template #confirmDeploy>
  <div class="modal-header main-modal-header" style="border-bottom: none">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0">
      {{ locale('locale_key.wizard.create_questionnaire.create_subject', { subject: 'request' }) }}
    </h4>
    <button type="button" class="btn close" (click)="closeModal()" *ngIf="!loadingInProgress">
      <span aria-hidden="true"><i class="las la-times"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      {{
        locale('locale_key.connected-actions.connected-actions.connected-actions.create_subject_warning', {
          subject: 'request'
        })
      }}
    </p>
    <p>{{ locale('locale_key.connected-actions.apply_changes_on_creation', { subject: 'request' }) }}</p>
    <div class="row mb-2" *ngIf="categoryService.newEntities.length > 0">
      <div class="accordion" id="entitesAccordion">
        <h2 class="accordion-header" id="entityHeading">
          <div
            class="accordion-button bg-light-gray"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#entityCollapse"
            aria-expanded="true"
            aria-controls="entityCollapse"
          >
            <i class="las la-table fs-3 pe-2"></i>
            {{ locale('locale_key.connected_actions.data_categories_to_be_added') }}
          </div>
        </h2>
        <div
          id="entityCollapse"
          class="accordion-collapse collapse show"
          aria-labelledby="entityHeading"
          data-bs-parent="#entitesAccordion"
        >
          <span class="badge badge-pink w-auto py-auto m-2" *ngFor="let entity of categoryService.newEntities">
            {{ entity.getTaxonomyTitle(taxonomyInfo) }}
          </span>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="categoryService.dataPointsLength() > 0">
      <div class="accordion" id="dataPointAcc">
        <h2 class="accordion-header" id="dpHeading">
          <div
            class="accordion-button bg-light-gray"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#dpCollapse"
            aria-expanded="true"
            aria-controls="dpCollapse"
          >
            <i class="las la-table fs-3 pe-2"></i>
            {{ locale('locale_key.connected_actions.data_points_to_be_added') }}
          </div>
        </h2>
        <div
          id="dpCollapse"
          class="accordion-collapse collapse show"
          aria-labelledby="dpHeading"
          data-bs-parent="#dataPointAcc"
        >
          <ng-container *ngFor="let entity of categoryService.getNewDataPointEntites()">
            <span class="badge badge-info w-auto py-auto m-2 mb-0">
              {{ entity.getTaxonomyTitle(taxonomyInfo) }}
            </span>
            <ng-container *ngFor="let dp of categoryService.getNewDataPoints(entity); let last = last">
              <span class="badge badge-pink w-auto py-auto mx-2">
                {{ dp.getLabel() }}
              </span>
              <ng-container *ngIf="!last">/</ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer" style="border-top: none">
    <ng-container *ngIf="!loadingInProgress">
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
      <button type="button" class="btn primary-button custom-button" (click)="deployTaxonomy()">
        {{ locale('locale_key.pages.insight_detail.create_and_apply') }}
      </button>
    </ng-container>
    <div class="ball-grid-pulse loader-primary" *ngIf="loadingInProgress">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
  </div>
</ng-template>

<ng-template #questHeader>
  <div class="w-100 p-0 me-3 text-lg-end mt-1" style="background-color: transparent">
    <!-- Search Icon -->
    <div class="search-container d-inline-flex align-items-center">
      <a (click)="showSearchField = !showSearchField" title="Search">
        <i class="la size-15" [ngClass]="showSearchField ? 'la-search-minus mt-1' : 'la-search-plus'"></i>
      </a>
      <input
        *ngIf="showSearchField"
        type="text"
        class="form-control ms-2"
        placeholder="{{ slocale('Search') }}"
        [(ngModel)]="searchText"
        (input)="filterQuestions()"
        style="width: auto"
      />
    </div>

    <!-- Collapse/Expand Icon -->
    <div class="my-auto d-inline-flex align-items-center mx-2">
      <a
        class="underline mx-1 my-auto"
        (click)="expandCollapseAll()"
        title="{{ allExpanded ? 'Collapse all' : 'Expand all' }}"
      >
        <i class="la size-15" [ngClass]="allExpanded ? 'la-compress' : 'la-expand'"></i>
      </a>
    </div>

    <!-- Mapping Toggle Icon -->
    <div class="row text-end my-auto d-inline-flex align-items-center me-2" *ngIf="isQuestionnaireInPreviewMode">
      <a class="p-1 cursor" (click)="toggleEditMapping()" title="Show/Hide mapping">
        <i class="las size-15" [ngClass]="editMapping ? 'la-sitemap' : 'la-sitemap'"></i>
      </a>
    </div>

    <!-- Edit Mode Links -->
    <div class="row text-end my-auto d-inline-flex align-items-center me-2" *ngIf="!isCSRDQuestionnaire">
      <a (click)="enterQuestionnaireEditMode()" *ngIf="isQuestionnaireInPreviewMode">
        <i class="la la-edit size-15"></i>
      </a>
      <a (click)="enterQuestionnairePreviewMode()" *ngIf="!isQuestionnaireInPreviewMode">
        <i class="la la-eye size-15"></i>
      </a>
    </div>
  </div>
</ng-template>
