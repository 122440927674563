import { REQUEST_TASK_STATUS } from '../model/data-suppliers/request/RequestFe'

export class DateUtil {
  static months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  public static toString(date: Date): string {
    return [date.getFullYear(), ('0' + (date.getMonth() + 1)).slice(-2), ('0' + date.getDate()).slice(-2)].join('-')
  }

  public static toString2(date: Date): string {
    return `${('0' + date.getDate()).slice(-2)} ${DateUtil.months[date.getMonth()]}, ${date.getFullYear()}`
  }

  public static toString3(date: Date): string {
    return `${('0' + date.getDate()).slice(-2)} ${DateUtil.months[date.getMonth()]}, ${date.getFullYear()} (${date.getHours()}:${('0' + date.getMinutes()).slice(-2)})`
  }

  public static toString4(date: Date): string {
    return `${DateUtil.months[date.getMonth()]} ${('0' + date.getDate()).slice(-2)}, ${date.getFullYear()} (${date.getHours()}:${('0' + date.getMinutes()).slice(-2)})`
  }

  public static toString5(date: Date): string {
    return `${DateUtil.months[date.getMonth()]} ${('0' + date.getDate()).slice(-2)}, ${date.getFullYear()}`
  }

  public static getTimeDifference(date1: Date, date2: Date) {
    if (date1 && date2) {
      var Difference_In_Time = date1.getTime() - date2.getTime()
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24)
      return Difference_In_Days
    }
  }

  public static getDeadlineClass(date: Date, status?: string): string {
    const today = new Date()
    var Difference_In_Time = -today.getTime() + date?.getTime()
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24)

    if (status == REQUEST_TASK_STATUS.APPROVED || status == REQUEST_TASK_STATUS.CLOSED) {
      return 'badge-gray'
    }

    if (Difference_In_Days < 0) {
      return 'badge-warning'
    } else if (Difference_In_Days <= 30) {
      return 'badge-alert'
    } else if (Difference_In_Days > 30) {
      return 'badge-success'
    }
  }

  public static getMinDate(date: Date): string {
    if (date instanceof Date) {
      return date.toISOString().split('T')[0]
    }
    return '1900-01-01'
  }

  public static isValidYear(date: string | Date | null): boolean {
    if (!date) return false

    const parsedDate = new Date(date)
    if (isNaN(parsedDate.getTime())) return false

    const year = parsedDate.getFullYear()
    return year >= 1900 && year <= 2100
  }

  public static isValidDateRange(from: string | Date | null, to: string | Date | null): boolean {
    if (!from || !to) return false

    const fromDate = new Date(from)
    const toDate = new Date(to)

    return fromDate < toDate && DateUtil.isValidYear(fromDate) && DateUtil.isValidYear(toDate)
  }

  public static isValidSingleDate(date: string | Date | null): boolean {
    return !!date && DateUtil.isValidYear(date)
  }

  public static isInvalidDateFrom(dateInputFrom: HTMLInputElement | Date): boolean {
    const fromDate = dateInputFrom instanceof HTMLInputElement ? dateInputFrom.valueAsDate : dateInputFrom
    return fromDate && !DateUtil.isValidYear(fromDate)
  }

  public static isInvalidDateTo(dateInputFrom: HTMLInputElement | Date, dateInputTo: HTMLInputElement | Date): boolean {
    const fromDate = dateInputFrom instanceof HTMLInputElement ? dateInputFrom.valueAsDate : dateInputFrom
    const toDate = dateInputTo instanceof HTMLInputElement ? dateInputTo.valueAsDate : dateInputTo

    return fromDate && toDate && (!DateUtil.isValidYear(toDate) || fromDate > toDate)
  }

  public static isInvalidDateRange(
    dateInputElementFrom: HTMLInputElement,
    dateInputElementTo: HTMLInputElement
  ): boolean {
    const fromDate = dateInputElementFrom?.valueAsDate
    const toDate = dateInputElementTo?.valueAsDate

    return fromDate && toDate && (!DateUtil.isValidYear(fromDate) || !DateUtil.isValidYear(toDate) || fromDate > toDate)
  }

  public static toIsoDateString(date: string | Date): string {
    const dateObj = new Date(date)
    return dateObj.toISOString().split('T')[0] // Convert to 'yyyy-MM-dd'
  }
}
