import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { CSRDDisclosureSettingsStatus, CSRDProjectInfoFe } from 'src/app/model/project/CSRDProjectInfoFe'
import { DrDatapointsEditComponent } from './dr-datapoints-edit/dr-datapoints-edit.component'
import { DR_TABS, DR_DATAPOINTS_PAGES } from '../Constants'

@Component({
  selector: 'csrd-dr-datapoints',
  templateUrl: './dr-datapoints.component.html',
  styleUrls: ['./dr-datapoints.component.scss']
})
export class DrDatapointsComponent implements OnInit {
  public DR_DATAPOINTS_PAGES = DR_DATAPOINTS_PAGES
  activeDrDatapointsPage: DR_DATAPOINTS_PAGES = DR_DATAPOINTS_PAGES.VIEW

  @Output() switchProjectsPage = new EventEmitter<boolean>()
  @Output() switchCsrdProjectPage = new EventEmitter<boolean>()
  @Output() switchActiveDrTab = new EventEmitter<{ tab: DR_TABS }>()

  @Input() activeCsrdProject: CSRDProjectInfoFe
  @Input() activeTopic: any
  @Input() activeEsrs: any
  @Input() activeDr: any

  @ViewChild(DrDatapointsEditComponent) drDatapointsEditComponent!: DrDatapointsEditComponent

  constructor() {}

  ngOnInit(): void {
    if (
      this.activeDr.status == CSRDDisclosureSettingsStatus.NOT_STARTED ||
      this.activeDr.status == CSRDDisclosureSettingsStatus.DRAFTING ||
      this.activeDr.status == CSRDDisclosureSettingsStatus.ASSESSING
    ) {
      this.activeDrDatapointsPage = DR_DATAPOINTS_PAGES.EDIT
    }
  }

  switchDrDatapointsPage(page: DR_DATAPOINTS_PAGES) {
    this.activeDrDatapointsPage = page
  }

  startAssessment() {
    this.activeDrDatapointsPage = DR_DATAPOINTS_PAGES.EDIT
  }

  canSwitchCsrdProjectPage() {
    let canSwitch = true
    if (this.activeDrDatapointsPage == DR_DATAPOINTS_PAGES.EDIT) {
      canSwitch = this.drDatapointsEditComponent.canSwitchCsrdProjectPage()
    }
    return canSwitch
  }

  goSwitchCsrdProjectPage() {
    this.switchCsrdProjectPage.emit(true)
  }

  canSwitchProjectsPage() {
    let canSwitch = true
    if (this.activeDrDatapointsPage == DR_DATAPOINTS_PAGES.EDIT) {
      canSwitch = this.drDatapointsEditComponent.canSwitchProjectsPage()
    }
    return canSwitch
  }

  goSwitchProjectsPage() {
    this.switchProjectsPage.emit(true)
  }
}
