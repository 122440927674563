import { TaxonomyInfoFe } from 'src/app/model/taxonomy/TaxonomyInfoFe'
import { EntityFe } from 'src/app/model/taxonomy/EntityFe'
import { find, isEmpty } from 'lodash'

/*
Util class used for creating and handling taxonomies
Stores taxonomy keys in maps for quick access
*/

export class TaxonomyUtils {
  public static loadTaxonomyInaMap(taxonomy: TaxonomyInfoFe) {
    const taxonomyKeyMap = new Map()
    taxonomy.entities.forEach((entity) => {
      taxonomyKeyMap.set(entity.key, entity)
    })

    return taxonomyKeyMap
  }

  public static loadTaxonomyAndIndexInaMap(taxonomy: TaxonomyInfoFe) {
    const taxonomyKeyMap = new Map()

    taxonomy.entities.forEach((entity, index) => {
      taxonomyKeyMap.set(entity.key, { entity, index })
    })

    return taxonomyKeyMap
  }
  /*
    Fix any wrong state ( isNew, isEdited, isRemoved )
    context : when trying to find a way to handle the edit states
    without sending it to the backend (in the end had to send them to backend) 
    alot of entities got out of sync and weren't easy to consolidate, 
    so created this function to fix them.. and decided leaving it here would be useful
   */
  public static consolidateTaxonomyDiversion(newTaxonomy: TaxonomyInfoFe, depTaxonomy: TaxonomyInfoFe) {
    let hasDraft = false

    const taxonomyKeyMap = TaxonomyUtils.loadTaxonomyInaMap(depTaxonomy)
    //isNew
    newTaxonomy.entities.forEach((newEntity) => {
      const deployedEntity = taxonomyKeyMap.get(newEntity.key)

      const isDraftEntity = TaxonomyUtils.checkForDraftInEntity(newEntity, deployedEntity)
      if (isDraftEntity) {
        hasDraft = true
      }
    })

    return hasDraft
  }

  public static checkForDraftInEntity(newEntity: EntityFe, deployedEntity: EntityFe) {
    let hasDraft = false

    if (isEmpty(deployedEntity)) {
      newEntity.isNew = true
      hasDraft = true
    } else {
      newEntity.columns.forEach((newColumn) => {
        const deployedColumn = find(deployedEntity.columns, ['key', newColumn.key])
        if (isEmpty(deployedColumn)) {
          newColumn.isNew = true
          hasDraft = true
        }
      })
    }
    return hasDraft
  }

  public static checkForDraft(newTaxonomy: TaxonomyInfoFe) {
    let hasDraft = false
    newTaxonomy.entities.forEach((newEntity) => {
      if (newEntity.isNew || newEntity.isEdited || newEntity.isRemoved) {
        hasDraft = true
      }

      if (hasDraft) return

      newEntity.columns.forEach((column) => {
        if (column.isNew || column.isEdited || column.isRemoved) {
          hasDraft = true
        }
      })
    })

    return hasDraft
  }
}
