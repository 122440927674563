export const DATE_FORMAT = 'dd MMM, yyyy'
export const DATE_FORMAT_DASHED = 'dd-MM-yyyy'
export const DATE_FORMAT_ISO = 'yyyy-MM-dd'
export const DATE_FORMAT_US = 'MM/dd/yyyy'
export const UNIT_VALUE = 'unit-value'
export const UNIT = 'unit'

export enum LEAVE_PAGE_SOURCES {
  'BACK' = 'BACK',
  'ALL_PROJECTS' = 'ALL_PROJECTS'
}
