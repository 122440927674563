<div class="d-flex flex-column h-100 me-1">
  <!-- empty toolbar + page-header and 3dots menu columns-->
  <div class="d-flex flex-row">
    <!-- empty-->
    <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()"></div>
    <!-- header + 3 dots-->
    <div class="flex-fill">
      <div class="d-flex flex-row justify-content-between flex-wrap" [class.ps-2]="screenSize.isSmallerSize()">
        <!-- page title and save/edit badge-->
        <div class="d-flex flex-row justify-content-start align-items-center">
          <h3 class="text-upppercase">{{ locale('locale_key.pages.data_categories.title') }}</h3>
          <div *ngIf="isCurrentStateEditing() && !hasDraft" class="toolbar_header_indicator saved ms-2">
            {{ locale('locale_key.pages.data_category.saved') }}
          </div>
          <div *ngIf="isCurrentStateEditing() && hasDraft" class="toolbar_header_indicator draft ms-2">
            {{ locale('locale_key.general.state.draft') }}
          </div>
        </div>
        <div class="d-flex flex-row just-content-between flex-wrap">
          <!-- language dropdown-->
          <div id="lang-dropdown" [class.disabled]="isBackButtonDisabled()" class="btn-group">
            <button
              class="btn dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="true"
            >
              <span *ngIf="pageLanguage">
                <i class="{{ pageLanguage.icon }} me-2"></i>
                {{ pageLanguage.label }}
              </span>
            </button>
            <div class="dropdown-menu">
              <ng-container *ngFor="let language of languages">
                <a
                  class="dropdown-item m-0 px-3"
                  style="color: var(--main-dark-color); cursor: pointer"
                  *ngIf="pageLanguage?.code != language.code"
                  (click)="setPageLanguage(language)"
                >
                  <i class="{{ language.icon }} me-2"></i>
                  {{ language.label }}
                </a>
              </ng-container>
            </div>
          </div>
          <!-- legend items-->
          <div class="edit_legend" *ngIf="isCurrentStateEditing()">
            <div class="edit_legend_key saved">
              <div class="edit_legend_color"></div>
              <div class="edit_legend_label">{{ locale('locale_key.pages.data_category.saved') }}</div>
            </div>
            <div class="edit_legend_key edited">
              <div class="edit_legend_color"></div>
              <div class="edit_legend_label">{{ locale('locale_key.pages.data_category.edited') }}</div>
            </div>
            <div class="edit_legend_key new">
              <div class="edit_legend_color"></div>
              <div class="edit_legend_label">{{ locale('locale_key.pages.data_category.new') }}</div>
            </div>
            <div class="edit_legend_key removed">
              <div class="edit_legend_color"></div>
              <div class="edit_legend_label">{{ locale('locale_key.pages.data_category.removed') }}</div>
            </div>
          </div>
          <!-- search bar-->
          <div class="search" *ngIf="showSearchCategory()">
            <i class="las la-search icon"></i>
            <input type="text" placeholder="{{ locale('locale_key.general.search') }}" [(ngModel)]="searchFilter" />
          </div>
        </div>
        <!-- dropdown menu instead of toolbar for smaller pages-->
        <div class="btn-group" dropdown placement="bottom left" *ngIf="screenSize.isSmallerSize()">
          <button
            id="button-alignment"
            dropdownToggle
            type="button"
            class="btn page-toolbar-dropdown-toggle ms-4 pt-0 mt-0"
            aria-controls="dropdown-alignment"
          >
            <i class="la la-ellipsis-h fs-2 pt-0 mt-0" style="rotate: 90deg"></i>
          </button>
          <ul
            id="dropdown-alignment"
            *dropdownMenu
            class="dropdown-menu dropdown-menu-right page-toolbar-dropdown-menu"
            role="menu"
            aria-labelledby="button-alignment"
          >
            <ng-container *ngFor="let toolbarGroup of pageToolbar; let groupIndex = index">
              <div>
                <ng-container *ngFor="let toolbarButton of toolbarGroup">
                  <li role="menuitem">
                    <button
                      type="button"
                      class="btn"
                      (click)="handleToolbarAction(toolbarButton.actionName)"
                      *ngIf="toolbarButton.visible()"
                    >
                      <i [class]="toolbarButton.icon"></i>
                      <span>{{ toolbarButton.longLabel }}</span>
                    </button>
                  </li>
                </ng-container>
                <li class="divider dropdown-divider" *ngIf="groupIndex + 1 < pageToolbar.length"></li>
              </div>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- toolbar + content columns -->
  <div class="flex-fill">
    <div class="d-flex flex-row h-100 data-categories-page">
      <!-- toolbar column-->
      <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()">
        <ng-container *ngFor="let toolbarGroup of pageToolbar">
          <div class="page-toolbar-button-group">
            <ng-container *ngFor="let toolbarButton of toolbarGroup">
              <button
                *ngIf="toolbarButton.visible()"
                type="button"
                class="btn page-toolbar-button"
                (click)="handleToolbarAction(toolbarButton.actionName)"
                data-bs-toggle="tooltip"
                data-placement="top"
                [title]="toolbarButton.tooltip"
                show-delay="3000"
              >
                <i class="fs-4" [class]="toolbarButton.icon"></i>
                <span>{{ toolbarButton.shortLabel }}</span>
              </button>
            </ng-container>
          </div>
        </ng-container>
        <div class="ball-pulse loader-primary" *ngIf="false">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <!-- content column-->
      <div class="flex-fill main-content">
        <!-- VIEW / EDIT DATACATEGORIES -->
        <ng-container *ngIf="currentPage == 'datacategories' && taxonomies && taxonomies.depTaxonomy.entities">
          <div
            class="accordion accordion-flush"
            [class.hasSuggestedLvls]="isCurrentStateEditing() && getSuggestedlvl1s().length > 0"
          >
            <div
              *ngFor="let level_1 of getDataCategories(); let isFirst = first"
              class="accordion-item level_1_container"
            >
              <div
                class="accordion-header"
                [class.new]="isCurrentStateEditing() && level_1.isNew"
                [class.edited]="isCurrentStateEditing() && level_1.isEdited"
                [class.removed]="isCurrentStateEditing() && level_1.isRemoved"
              >
                <div class="level_1_header">
                  <i class="las la-grip-lines edit_control drag" *ngIf="isCurrentStateEditing()"></i>
                  <i
                    class="fs-3 info las la-exclamation-triangle warning-info"
                    *ngIf="!isLoading && !isDCatContainsDisplayLang(level_1)"
                    title="{{
                      locale('locale_key.pages.data_category.language.missing.tooltip', {
                        language: pageLanguage?.label
                      })
                    }}"
                  ></i>
                  <i
                    class="icon"
                    [class.new]="level_1.isNew"
                    [class.edited]="level_1.isEdited"
                    [class.removed]="level_1.isRemoved"
                    [class]="dcIcons[level_1.key]"
                  ></i>
                  <span
                    class="level_1_label"
                    [class.new]="level_1.isNew"
                    [class.edited]="level_1.isEdited"
                    [class.removed]="level_1.isRemoved"
                  >
                    {{ resolveString(level_1.label) }}
                  </span>
                  <i
                    class="la la-edit edit_control edit"
                    (click)="startEditCategory(level_1)"
                    *ngIf="shouldAllowToEditLevel1Category(level_1.key, level_1.isRemoved)"
                  ></i>
                  <i
                    (click)="startDeleteCategory(level_1)"
                    class="la la-trash-alt edit_control delete"
                    *ngIf="shouldAllowToEditLevel1Category(level_1.key, level_1.isRemoved)"
                  ></i>
                  <i
                    (click)="undoDeleteCategory(level_1)"
                    class="las la-undo-alt edit_control undo"
                    *ngIf="isCurrentStateEditing() && level_1.isRemoved && !isCsrdKey(level_1.key)"
                  ></i>
                </div>
                <div
                  class="accordion-button"
                  [class.collapsed]="false"
                  type="button"
                  data-bs-toggle="collapse"
                  [attr.data-bs-target]="'#' + level_1.key"
                ></div>
              </div>
              <div [id]="level_1.key" class="accordion-collapse collapse" [class.show]="true">
                <div class="accordion-body">
                  <div
                    class="empty_level_1_msg"
                    *ngIf="isCurrentStateEditing() && getLevel2DataCategories(level_1).length == 0"
                  >
                    <div class="heading">
                      {{
                        locale('locale_key.pages.data_category.data_category.what_to_track', {
                          label: resolveString(level_1.label)
                        })
                      }}
                    </div>
                    <div class="sub_heading">
                      {{ locale('locale_key.pages.data_category.data_category.add_categories') }}
                    </div>
                  </div>
                  <div *ngFor="let level_2 of getLevel2DataCategories(level_1)" class="level_2_container">
                    <div
                      [class.new]="level_2.isNew"
                      [class.edited]="level_2.isEdited"
                      [class.removed]="level_2.isRemoved"
                      class="level_2_header"
                    >
                      <i
                        class="las la-grip-lines edit_control drag"
                        *ngIf="isCurrentStateEditing() && !isCsrdKey(level_2.key)"
                      ></i>
                      <i
                        class="fs-3 info las la-exclamation-triangle warning-info p-1"
                        *ngIf="!isLoading && !isDCatContainsDisplayLang(level_2)"
                        title="{{
                          locale('locale_key.pages.data_category.language.missing.tooltip', {
                            language: pageLanguage?.label
                          })
                        }}"
                      ></i>
                      <span
                        [class.new]="level_2.isNew"
                        [class.edited]="level_2.isEdited"
                        [class.removed]="level_2.isRemoved"
                        class="level_2_label"
                      >
                        {{ resolveString(level_2.label) }}
                      </span>
                      <i
                        class="la la-edit edit_control edit"
                        (click)="startEditCategory(level_2)"
                        *ngIf="isCurrentStateEditing() && !level_2.isRemoved && !isCsrdKey(level_2.key)"
                      ></i>
                      <i
                        (click)="startDeleteCategory(level_2)"
                        class="la la-trash-alt edit_control delete"
                        *ngIf="isCurrentStateEditing() && !level_2.isRemoved && !isCsrdKey(level_2.key)"
                      ></i>
                      <i
                        (click)="undoDeleteCategory(level_2)"
                        class="las la-undo-alt edit_control undo"
                        *ngIf="isCurrentStateEditing() && level_2.isRemoved"
                      ></i>
                      <div
                        class="level_2_add_control"
                        *ngIf="currentState === 'editing' && !isCSRDSecondLevelKey(level_2.key)"
                      >
                        <div class="dropdown">
                          <div
                            class="dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="outside"
                            aria-expanded="false"
                            (click)="addControlDropdownOpened(level_2)"
                          >
                            <i class="icon las la-plus"></i>
                            <span class="add_label">
                              {{
                                locale('locale_key.pages.data_category.data_category.add_to_label', {
                                  label: resolveString(level_2.label)
                                })
                              }}
                            </span>
                          </div>
                          <div [id]="'dropdown_menu_' + level_2.key" class="dropdown-menu">
                            <ng-container *ngFor="let suggestedCategory of getSuggestedDataCategories(level_2.key)">
                              <div
                                (click)="addSuggestedCategory({ suggestedCategory, parent: level_2 })"
                                class="dropdown-item"
                              >
                                {{ resolveString(suggestedCategory.label) }}
                              </div>
                              <div class="dropdown-divider"></div>
                            </ng-container>
                            <div
                              [id]="'add_custom_category_container_' + level_2.key"
                              class="level_2_add_custom_category_container viewing"
                            >
                              <div class="level_2_add_custom_category_cta" (click)="startAddCustomCategory(level_2)">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="17"
                                  viewBox="0 0 16 17"
                                  fill="none"
                                >
                                  <path
                                    d="M8 3.83325V13.1666"
                                    stroke="#374659"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M3.33334 8.5H12.6667"
                                    stroke="#374659"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                                <span>
                                  {{ locale('locale_key.pages.data_category.data_category.add_custom_category') }}
                                </span>
                              </div>
                              <input
                                [(ngModel)]="addCustomCategoryInput"
                                class="level_2_add_custom_category_input"
                                type="text"
                                placeholder="Enter name"
                              />
                              <div class="level_2_add_custom_category_control" (click)="addCustomCategory(level_2)">
                                <i class="icon las la-plus"></i>
                                <span class="add_label">{{ locale('locale_key.general.buttons.add') }}</span>
                              </div>
                            </div>
                            <div class="error">
                              {{ locale('locale_key.pages.data_category.data_category.name_already_exists') }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="empty_level_2_msg"
                      *ngIf="
                        isCurrentStateEditing() &&
                        (!getLevel3DataCategories(level_2) || getLevel3DataCategories(level_2).length == 0)
                      "
                    >
                      <div class="heading">
                        {{
                          locale('locale_key.pages.data_category.data_category.what_to_add', {
                            label: resolveString(level_2.label)
                          })
                        }}
                      </div>
                      <div class="sub_heading">
                        {{ locale('locale_key.pages.data_category.data_category.start_adding') }}
                      </div>
                    </div>

                    <div class="level_3_container">
                      <div
                        *ngFor="let level_3 of getLevel3DataCategories(level_2)"
                        class="level_3_box"
                        (click)="viewDatapoints({ level_1, level_2, level_3 })"
                      >
                        <div
                          [class.new]="level_3.isNew"
                          [class.edited]="level_3.isEdited"
                          [class.removed]="level_3.isRemoved"
                          class="level_3_indicator"
                        ></div>
                        <div class="level_3_content">
                          <i
                            class="las la-grip-lines edit_control drag"
                            *ngIf="isCurrentStateEditing() && !isCsrdKey(level_3.key)"
                          ></i>
                          <i
                            class="fs-3 info las la-exclamation-triangle warning-info"
                            *ngIf="!isLoading && !isDCatContainsDisplayLang(level_3)"
                            title="{{
                              locale('locale_key.pages.data_category.language.missing.tooltip', {
                                language: pageLanguage?.label
                              })
                            }}"
                          ></i>
                          <i
                            [class.new]="level_3.isNew"
                            [class.edited]="level_3.isEdited"
                            [class.removed]="level_3.isRemoved"
                            class="icon"
                            [class]="dcIcons[level_1.key]"
                          ></i>
                          <span
                            [class.new]="level_3.isNew"
                            [class.edited]="level_3.isEdited"
                            [class.removed]="level_3.isRemoved"
                            class="level_3_label"
                          >
                            {{ resolveString(level_3.label) }}
                          </span>
                          <i
                            class="la la-copy me-2"
                            (click)="openDuplicateCategoryModal(level_3, level_2, level_1); $event.stopPropagation()"
                            *ngIf="isCurrentStateEditing() && !isCsrdKey(level_3.key) && !level_3.isRemoved"
                          ></i>
                          <i
                            class="la la-edit edit_control edit"
                            (click)="startEditCategory(level_3); $event.stopPropagation()"
                            *ngIf="isCurrentStateEditing() && !isCsrdKey(level_3.key) && !level_3.isRemoved"
                          ></i>
                          <i
                            (click)="startDeleteCategory(level_3); $event.stopPropagation()"
                            class="la la-trash-alt edit_control delete"
                            *ngIf="isCurrentStateEditing() && !isCsrdKey(level_3.key) && !level_3.isRemoved"
                          ></i>
                          <i
                            (click)="undoDeleteCategory(level_3); $event.stopPropagation()"
                            class="las la-undo-alt edit_control undo"
                            *ngIf="isCurrentStateEditing() && !isCsrdKey(level_3.key) && level_3.isRemoved"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="level_1_add_control" *ngIf="currentState === 'editing'">
                    <div class="dropup">
                      <div
                        class="dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                        aria-expanded="false"
                        (click)="addControlDropdownOpened(level_1)"
                      >
                        <i class="icon las la-plus"></i>
                        <span class="add_label">
                          {{
                            locale('locale_key.pages.data_category.data_category.add_to_label', {
                              label: resolveString(level_1.label)
                            })
                          }}
                        </span>
                      </div>
                      <div [id]="'dropdown_menu_' + level_1.key" class="dropdown-menu">
                        <ng-container *ngFor="let suggestedCategory of getSuggestedDataCategories(level_1.key)">
                          <div
                            (click)="addSuggestedCategory({ suggestedCategory, parent: level_1 })"
                            class="dropdown-item"
                          >
                            {{ resolveString(suggestedCategory.label) }}
                          </div>
                          <div class="dropdown-divider"></div>
                        </ng-container>
                        <div
                          [id]="'add_custom_category_container_' + level_1.key"
                          class="level_1_add_custom_category_container viewing"
                        >
                          <div class="level_1_add_custom_category_cta" (click)="startAddCustomCategory(level_1)">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="17"
                              viewBox="0 0 16 17"
                              fill="none"
                            >
                              <path
                                d="M8 3.83325V13.1666"
                                stroke="#374659"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M3.33334 8.5H12.6667"
                                stroke="#374659"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <span>
                              {{ locale('locale_key.pages.data_category.data_category.add_custom_category') }}
                            </span>
                          </div>
                          <input
                            [(ngModel)]="addCustomCategoryInput"
                            class="level_1_add_custom_category_input"
                            type="text"
                            placeholder="Enter name"
                          />
                          <div class="level_1_add_custom_category_control" (click)="addCustomCategory(level_1)">
                            <i class="icon las la-plus"></i>
                            <span class="add_label">{{ locale('locale_key.general.buttons.add') }}</span>
                          </div>
                        </div>
                        <div class="error">
                          {{ locale('locale_key.pages.data_category.data_category.name_already_exists') }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="add_lvl1_category_container" *ngIf="isCurrentStateEditing()">
            <div class="add_lvl1_control">
              <div class="dropdown">
                <div class="dropdown-toggle" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside">
                  <i class="icon las la-plus"></i>
                  <span class="add_label">{{ locale('locale_key.pages.data_categories.add_data_category') }}</span>
                </div>
                <div [id]="'dropdown_menu_add_lvl1'" class="dropdown-menu">
                  <ng-container *ngFor="let suggestedLvl1 of getSuggestedlvl1s()">
                    <div (click)="addsuggestedLvl1({ suggestedLvl1 })" class="dropdown-item">
                      {{ resolveString(suggestedLvl1.label) }}
                    </div>
                    <div class="dropdown-divider"></div>
                  </ng-container>
                  <div [id]="'add_custom_lvl1_category_container'" class="add_custom_lvl1_category_container viewing">
                    <div class="add_custom_lvl1_category_cta" (click)="startAddCustomLvl1Category()">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                        <path
                          d="M8 3.83325V13.1666"
                          stroke="#374659"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M3.33334 8.5H12.6667"
                          stroke="#374659"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <span>{{ locale('locale_key.pages.data_categories.button.add_custom_category') }}</span>
                    </div>
                    <input
                      [(ngModel)]="addCustomCategoryInput"
                      class="add_custom_lvl1_category_input"
                      type="text"
                      placeholder="Enter name"
                    />
                    <div class="add_custom_lvl1_category_control" (click)="addCustomLvl1Category()">
                      <i class="icon las la-plus"></i>
                      <span class="add_label">Add</span>
                    </div>
                  </div>
                  <div class="error">
                    {{ locale('locale_key.pages.data_category.data_category.name_already_exists') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- VIEW / EDIT DATA POINTS -->
        <ng-container *ngIf="currentPage == 'datapoints'">
          <div class="datapoints_page">
            <category-switcher
              [dataCategories]="dataCategories"
              [selectedDataCategory]="selectedDataCategory"
            ></category-switcher>

            <div class="datapoints" *ngIf="getDatapoints() as datapoints">
              <div class="datapoints-header">
                <div class="title">{{ locale('locale_key.pages.data_category.data_points') }}</div>
                <div class="spacer"></div>
                <div
                  *ngIf="isCurrentStateViewing()"
                  class="control show_master_table_container"
                  (click)="openMasterTable()"
                >
                  <i class="show_master_table_icon las la-table"></i>
                  <span class="show_master_table_label">
                    {{ locale('locale_key.pages.data_category.go_to_master_table') }}
                  </span>
                </div>
                <div class="control search_container">
                  <i class="search_icon las la-search"></i>
                  <input
                    class="search_input"
                    type="text"
                    placeholder="{{ locale('locale_key.general.search') }}"
                    [(ngModel)]="datapointSearchFilter"
                  />
                </div>
                <div
                  class="control edit_control add_datapoint_container"
                  (click)="startAddDatapoint()"
                  [class.disabled]="!isCurrentStateEditing()"
                >
                  <i class="add_datapoint_icon las la-plus"></i>
                  <span class="add_datapoint_label">{{ locale('locale_key.pages.data_category.add_data_point') }}</span>
                </div>
              </div>
              <div class="datapoints-list">
                <div class="empty_datapoints_list" *ngIf="datapoints.length == 0">
                  <div class="heading">
                    {{
                      locale('locale_key.pages.data_category.collect_data_in_label', {
                        label: resolveString(selectedDataCategory.level_3.label)
                      })
                    }}
                  </div>
                  <div class="sub_heading">{{ locale('locale_key.pages.data_category.add_data_points') }}</div>

                  <div class="example_datapoint_container">
                    <div class="example_datapoint_heading">
                      {{ locale('locale_key.pages.data_category.example_data_point_entry') }}
                    </div>
                    <div class="example_datapoint">
                      <div class="indicator"></div>
                      <div class="content">
                        <div class="section desciption">
                          <div class="heading">{{ locale('locale_key.pages.data_category.description') }}</div>
                          <div class="body">
                            {{ locale('locale_key.pages.data_category.emissions_from_all_transportation') }}
                          </div>
                        </div>
                        <div class="section shortname">
                          <div class="heading">{{ locale('locale_key.pages.data_category.short_name') }}</div>
                          <div class="body">{{ locale('locale_key.pages.data_category.emissions') }}</div>
                        </div>
                        <div class="section datatype">
                          <div class="heading">{{ locale('locale_key.pages.data_category.data_type') }}</div>
                          <div class="body">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                d="M5.38623 16.5414V14.0098H7.56592V3.28162H5.25V0.75H11.0398V14.0098H13.106V16.5414H5.38623Z"
                                fill="#374659"
                              />
                            </svg>
                            {{ locale('locale_key.pages.data_category.numbers') }} -
                            <span class="calculated">{{ locale('locale_key.pages.data_category.calculated') }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="datapoint" *ngFor="let datapoint of datapoints; let datapointIdx = index">
                  <div
                    (click)="viewDatapoint(datapoint)"
                    [class.new]="datapoint.isNew"
                    [class.edited]="datapoint.isEdited"
                    [class.removed]="datapoint.isRemoved"
                    class="indicator"
                  ></div>
                  <div class="ordinalControls" *ngIf="isCurrentStateEditing() && !datapoint.isRemoved">
                    <i
                      (click)="moveDatapointUp({ datapointIdx, datapoint })"
                      class="ordinalControl ordinalControlUp las la-angle-up"
                      *ngIf="datapointIdx != 0"
                    ></i>
                    <i
                      (click)="moveDatapointDown({ datapointIdx, datapoint })"
                      class="ordinalControl ordinalControlDown las la-angle-down"
                      *ngIf="datapointIdx != datapoints.length - 1"
                    ></i>
                  </div>
                  <div class="data">
                    <div (click)="viewDatapoint(datapoint)" class="attribute description" style="flex: 2">
                      <div class="title">{{ locale('locale_key.pages.data_category.description') }}</div>
                      <div class="body">
                        {{ datapoint.getDescription() }}
                      </div>
                    </div>
                    <div (click)="viewDatapoint(datapoint)" class="attribute short-name" style="flex: 1">
                      <div class="title">{{ locale('locale_key.pages.data_category.short_name') }}</div>
                      <div class="body">
                        {{ resolveLabel({ token: { datapoint }, deployed: isCurrentStateViewing() }) }}

                        <ng-container *ngIf="hasUnit({ datapoint })">
                          <b>( {{ resolveUnit({ token: { datapoint }, deployed: isCurrentStateViewing() }) }} )</b>
                        </ng-container>
                      </div>
                    </div>
                    <div (click)="viewDatapoint(datapoint)" class="attribute data_type" style="flex: 1">
                      <div class="title">{{ locale('locale_key.pages.data_category.data_type') }}</div>
                      <div class="body" [ngSwitch]="datapoint.datatype">
                        <ng-container *ngSwitchCase="'BOOLEAN'">
                          <div class="data_type_name">{{ locale('locale_key.general.data_type.boolean') }}</div>
                        </ng-container>

                        <ng-container *ngSwitchCase="'DATE'">
                          <i class="data_type_icon las la-calendar"></i>
                          <div class="data_type_name">{{ locale('locale_key.general.data_type.date') }}</div>
                        </ng-container>

                        <ng-container *ngSwitchCase="'STRING'">
                          <i class="data_type_icon las la-font"></i>
                          <div class="data_type_name">{{ locale('locale_key.general.data_type.text') }}</div>
                        </ng-container>

                        <ng-container *ngSwitchCase="'NUMERIC'">
                          <svg
                            class="data_type_icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="19"
                            viewBox="0 0 18 19"
                            fill="none"
                          >
                            <path
                              d="M5.38623 16.9545V14.4229H7.56592V3.6947H5.25V1.16309H11.0398V14.4229H13.106V16.9545H5.38623Z"
                              fill="#374659"
                            />
                          </svg>
                          <div class="data_type_name">{{ locale('locale_key.general.data_type.number') }}</div>
                          <div class="data_type_calculated" *ngIf="datapoint.providedBy == 'calculation'">
                            <span class="hyphen">-</span>
                            {{ locale('locale_key.pages.data_category.calculated') }}
                          </div>
                        </ng-container>

                        <ng-container *ngSwitchCase="'EMISSION_FACTOR'">
                          <svg
                            width="12"
                            height="14"
                            viewBox="0 0 12 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.44934 0.469727L4.76404 3.28003L3.72363 2.24072L3.32812 2.69006C1.36894 4.91081 0.375 6.92856 0.375 8.6875C0.375 11.4786 2.89838 13.75 6 13.75C9.10163 13.75 11.625 11.4786 11.625 8.6875C11.625 6.00887 8.70173 2.70591 6.95911 0.975098L6.44934 0.469727ZM6.67017 2.28796C8.11917 3.79884 10.5 6.5995 10.5 8.6875C10.5 10.0326 9.7239 11.2212 8.54443 11.9318C8.71238 11.5304 8.8125 11.088 8.8125 10.6167C8.8125 9.27739 7.86488 7.68872 7.07007 6.59241L6.59546 5.93652L5.34961 7.8053L4.55859 7.01428L4.18726 7.62732C3.52407 8.72194 3.1875 9.72739 3.1875 10.6167C3.1875 11.088 3.28762 11.5304 3.45557 11.9318C2.2761 11.2212 1.5 10.0326 1.5 8.6875C1.5 7.34369 2.28617 5.69315 3.78186 3.88977L4.98596 5.09497L6.67017 2.28796ZM6.62073 7.92725C7.3126 9.00556 7.6875 9.94395 7.6875 10.6167C7.6875 11.7243 6.93038 12.625 6 12.625C5.06962 12.625 4.3125 11.7243 4.3125 10.6167C4.3125 10.0981 4.47576 9.50439 4.79919 8.8457L5.52539 9.5708L6.62073 7.92725Z"
                              fill="#374659"
                            />
                          </svg>
                          <div class="data_type_name">{{ locale('locale_key.pages.emission.emission_factor') }}</div>
                        </ng-container>
                      </div>
                    </div>
                    <div class="show_calculation_control_box" style="flex: 1">
                      <div
                        class="show_calculation_control"
                        data-bs-toggle="dropdown"
                        *ngIf="datapoint.providedBy == 'calculation'"
                        (click)="loadCalculationBuilderForDatapoint = datapoint.key"
                      >
                        {{ locale('locale_key.pages.data_categories.show_calculation') }}
                      </div>
                      <div class="dropdown-menu show_calculation_viewer" *ngIf="datapoint.providedBy == 'calculation'">
                        <div class="show_calculation_viewer_title">
                          <span class="highlight">{{ resolveString(datapoint.label) }}</span>
                        </div>
                        <calculation-builder
                          [showEditMode]="false"
                          [showFormulaOnly]="true"
                          [selectedDataCategory]="selectedDataCategory"
                          [selectedDatapoint]="datapoint"
                          [showCalculationErrors]="false"
                          [isEditMode]="isCurrentStateEditing()"
                          *ngIf="loadCalculationBuilderForDatapoint == datapoint.key"
                        ></calculation-builder>
                      </div>
                    </div>
                    <i
                      (click)="startDeleteDatapoint(datapoint)"
                      class="la la-trash-alt edit_control delete"
                      *ngIf="isCurrentStateEditing() && !datapoint.isRemoved"
                    ></i>
                    <i
                      (click)="undoDeleteDatapoint(datapoint)"
                      class="las la-undo-alt edit_control undo"
                      *ngIf="isCurrentStateEditing() && datapoint.isRemoved"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- VIEW / EDIT DATA POINT -->
        <ng-container *ngIf="currentPage == 'datapoint'">
          <div class="datapoint_page">
            <category-switcher
              [dataCategories]="dataCategories"
              [selectedDataCategory]="selectedDataCategory"
              [selectedDatapoint]="selectedDatapoint"
              [showDatapoint]="true"
              (categorySwitched)="onCategorySwitched($event)"
              [isDisabled]="isBackButtonDisabled()"
            ></category-switcher>

            <div class="section description">
              <div class="title">
                <span class="label">{{ locale('locale_key.pages.data_category.description') }}</span>
                <i class="info las la-info-circle"></i>
              </div>
              <div class="form-group">
                <input
                  [(ngModel)]="editingDatapointDescription"
                  (ngModelChange)="onDatapointEdited({ what: 'description' })"
                  type="text"
                  [formControl]="datapointForm2.get('descriptionPageLangText')"
                  required
                  (input)="onChangeDatapointLabelDescription(false)"
                  *ngIf="isCurrentStateEditing()"
                  [ngClass]="{
                    'is-invalid':
                      pageLanguage.code === 'en' &&
                      datapointForm2.get('descriptionPageLangText').errors &&
                      (datapointForm2.get('descriptionPageLangText').touched ||
                        datapointForm2.get('descriptionPageLangText').dirty)
                  }"
                />
              </div>
              <span class="name" *ngIf="isCurrentStateViewing()">
                {{ selectedDatapoint.getDescription() }}
              </span>

              <div class="d-flex flex-row align-items-center">
                <div *ngIf="isCurrentStateEditing() && isDpDescriptionEnglishEmpty()">
                  <i
                    class="fs-3 info las la-exclamation-triangle warning-info p-1"
                    [title]="locale('locale_key.pages.data_category.add_english.warning.message')"
                    data-bs-toggle="tooltip"
                  ></i>
                </div>
                <div
                  class="control translations clickable-div"
                  *ngIf="isCurrentStateEditing()"
                  (click)="startEditDatapoint(false)"
                >
                  <i class="icon las la-plus"></i>
                  <span class="add_label">{{ getDatapointDescriptionLocale() }}</span>
                </div>
              </div>
            </div>

            <div class="section other_attributes">
              <div class="attributes">
                <div class="attribute short_name">
                  <div class="title">
                    <span>{{ locale('locale_key.pages.data_category.short_name') }}</span>
                    <i class="las la-info-circle"></i>
                  </div>
                  <div class="form-group">
                    <input
                      [(ngModel)]="editingDatapointLabel"
                      (ngModelChange)="onDatapointEdited({ what: 'label' })"
                      type="text"
                      [formControl]="datapointForm2.get('shortNamePageLangText')"
                      (input)="onChangeDatapointLabelDescription(true)"
                      *ngIf="isCurrentStateEditing()"
                      [ngClass]="{
                        'is-invalid':
                          (datapointForm2.get('shortNamePageLangText').errors &&
                            (datapointForm2.get('shortNamePageLangText').touched ||
                              datapointForm2.get('shortNamePageLangText').dirty)) ||
                          isDuplicateDatapointShortName()
                      }"
                    />
                    <div
                      *ngIf="
                        (datapointForm2.get('shortNamePageLangText').errors &&
                          (datapointForm2.get('shortNamePageLangText').touched ||
                            datapointForm2.get('shortNamePageLangText').dirty)) ||
                        isDuplicateDatapointShortName()
                      "
                      class="alert alert-warning d-flex flex-row border-1 mt-2 p-2"
                    >
                      <span *ngIf="datapointForm2.get('shortNamePageLangText').errors?.required">
                        {{ locale('locale_key.pages.data_category.input_required.message') }}
                      </span>
                      <span *ngIf="datapointForm2.get('shortNamePageLangText').errors?.pattern">
                        {{ locale('locale_key.pages.data_category.data_point.validation.message') }}
                      </span>
                      <span
                        *ngIf="
                          isDuplicateDatapointShortName() ||
                          datapointForm2.get('shortNamePageLangText').errors?.duplicateDatapointName
                        "
                      >
                        {{ locale('locale_key.pages.data_category.label.duplicate_name.message') }}
                      </span>
                    </div>
                  </div>
                  <span class="name" *ngIf="isCurrentStateViewing()">
                    {{ selectedDatapoint.getLabel() }}
                  </span>
                </div>
                <div class="attribute data_type">
                  <div class="title">
                    <span>{{ locale('locale_key.pages.data_category.data_type') }}</span>
                    <i class="las la-info-circle"></i>
                  </div>

                  <select
                    [(ngModel)]="selectedDatapoint.datatype"
                    (ngModelChange)="onDatapointEdited({ what: 'datatype' })"
                    *ngIf="isCurrentStateEditing() && !selectedDatapoint.deployed"
                  >
                    <option value="STRING">{{ locale('locale_key.general.data_type.text') }}</option>
                    <option value="NUMERIC">{{ locale('locale_key.general.data_type.number') }}</option>
                    <option value="DATE">{{ locale('locale_key.general.data_type.date') }}</option>
                    <option value="BOOLEAN">{{ locale('locale_key.general.data_type.boolean') }}</option>
                    <option value="EMISSION_FACTOR">{{ locale('locale_key.pages.emission.emission_factor') }}</option>
                  </select>

                  <div
                    class="data_type_name_container"
                    *ngIf="isCurrentStateViewing() || selectedDatapoint.deployed"
                    [ngSwitch]="selectedDatapoint.datatype"
                  >
                    <ng-container *ngSwitchCase="'BOOLEAN'">
                      <div class="data_type_name">{{ locale('locale_key.general.data_type.boolean') }}</div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'DATE'">
                      <i class="data_type_icon las la-calendar"></i>
                      <div class="data_type_name">{{ locale('locale_key.general.data_type.date') }}</div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'STRING'">
                      <i class="data_type_icon las la-font"></i>
                      <div class="data_type_name">{{ locale('locale_key.general.data_type.text') }}</div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'NUMERIC'">
                      <svg
                        class="data_type_icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                      >
                        <path
                          d="M5.38623 16.9545V14.4229H7.56592V3.6947H5.25V1.16309H11.0398V14.4229H13.106V16.9545H5.38623Z"
                          fill="#374659"
                        />
                      </svg>
                      <div class="data_type_name">{{ locale('locale_key.general.data_type.number') }}</div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'EMISSION_FACTOR'">
                      <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6.44934 0.469727L4.76404 3.28003L3.72363 2.24072L3.32812 2.69006C1.36894 4.91081 0.375 6.92856 0.375 8.6875C0.375 11.4786 2.89838 13.75 6 13.75C9.10163 13.75 11.625 11.4786 11.625 8.6875C11.625 6.00887 8.70173 2.70591 6.95911 0.975098L6.44934 0.469727ZM6.67017 2.28796C8.11917 3.79884 10.5 6.5995 10.5 8.6875C10.5 10.0326 9.7239 11.2212 8.54443 11.9318C8.71238 11.5304 8.8125 11.088 8.8125 10.6167C8.8125 9.27739 7.86488 7.68872 7.07007 6.59241L6.59546 5.93652L5.34961 7.8053L4.55859 7.01428L4.18726 7.62732C3.52407 8.72194 3.1875 9.72739 3.1875 10.6167C3.1875 11.088 3.28762 11.5304 3.45557 11.9318C2.2761 11.2212 1.5 10.0326 1.5 8.6875C1.5 7.34369 2.28617 5.69315 3.78186 3.88977L4.98596 5.09497L6.67017 2.28796ZM6.62073 7.92725C7.3126 9.00556 7.6875 9.94395 7.6875 10.6167C7.6875 11.7243 6.93038 12.625 6 12.625C5.06962 12.625 4.3125 11.7243 4.3125 10.6167C4.3125 10.0981 4.47576 9.50439 4.79919 8.8457L5.52539 9.5708L6.62073 7.92725Z"
                          fill="#374659"
                        />
                      </svg>
                      <div class="data_type_name">{{ locale('locale_key.pages.emission.emission_factor') }}</div>
                    </ng-container>
                  </div>
                </div>
                <div class="attribute unit">
                  <div class="title" *ngIf="selectedDatapoint.datatype == 'NUMERIC'">
                    <span>{{ locale('locale_key.pages.data_category.unit') }}</span>
                    <i class="las la-info-circle"></i>
                  </div>
                  <span class="name" *ngIf="isCurrentStateViewing() && selectedDatapoint.datatype == 'NUMERIC'">
                    <ng-container *ngIf="!selectedDatapoint.unit">
                      {{ locale('locale_key.pages.emission.no_unit') }}
                      <span class="symbol">()</span>
                    </ng-container>
                    <ng-container *ngIf="selectedDatapoint.unit">
                      {{ selectedDatapoint.unit?.name }}
                      <span class="symbol">({{ getSymbol(selectedDatapoint.unit) }})</span>
                    </ng-container>
                  </span>
                  <div class="clickable-div">
                    <unit-selector
                      *ngIf="isCurrentStateEditing() && selectedDatapoint.datatype == 'NUMERIC'"
                      [mode]="'select'"
                      [selectBy]="'symbol'"
                      [selectedUnitSymbol]="selectedUnitContainer?.selectedUnit?.symbol"
                      [selectedUnitContainer]="selectedUnitContainer"
                      [source]="'add_datapoint_modal'"
                      [customUnits]="getCustomUnits()"
                      [includeMeasurementTypes]="getMeasurementTypeLimits()"
                      [unitsByMeasurementType]="getUnitsByMeasurementType()"
                      (selectedUnitChanged)="onSelectedUnitChanged($event)"
                    ></unit-selector>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-row align-items-center">
                <div *ngIf="isCurrentStateEditing() && isDpLabelEnglishEmpty()">
                  <i
                    class="fs-3 info las la-exclamation-triangle warning-info p-1"
                    [title]="locale('locale_key.pages.data_category.add_english.warning.message')"
                    data-bs-toggle="tooltip"
                  ></i>
                </div>
                <div
                  class="control translations clickable-div"
                  *ngIf="isCurrentStateEditing()"
                  (click)="startEditDatapoint(true)"
                >
                  <i class="icon las la-plus"></i>
                  <span class="add_label">{{ getDatapointLabelLocale() }}</span>
                </div>
              </div>
            </div>

            <div class="section providedBy">
              <div class="heading" *ngIf="selectedDatapoint.datatype != 'EMISSION_FACTOR'">
                {{ locale('locale_key.pages.data_category.how_data_provided') }}
              </div>
              <div class="heading" *ngIf="selectedDatapoint.datatype == 'EMISSION_FACTOR'">
                {{ locale('locale_key.pages.emission.how_will_emission_factor_be_added') }}
              </div>

              <div
                class="value"
                *ngIf="
                  isCurrentStateViewing() &&
                  selectedDatapoint.providedBy == 'user' &&
                  selectedDatapoint.datatype == 'NUMERIC'
                "
              >
                {{ locale('locale_key.pages.data_category.entered_by_users') }}
              </div>

              <div
                class="value"
                *ngIf="
                  isCurrentStateViewing() &&
                  selectedDatapoint.providedBy == 'user' &&
                  selectedDatapoint.datatype == 'EMISSION_FACTOR'
                "
              >
                {{ locale('locale_key.pages.emission.added_when_entering_data') }}
              </div>

              <div
                class="value"
                *ngIf="
                  isCurrentStateViewing() &&
                  selectedDatapoint.providedBy == 'calculation' &&
                  selectedDatapoint.datatype == 'NUMERIC'
                "
              >
                {{ locale('locale_key.pages.data_category.calculated_by_system') }}
              </div>

              <div
                class="value"
                *ngIf="
                  isCurrentStateViewing() &&
                  selectedDatapoint.providedBy == 'pre_determined' &&
                  selectedDatapoint.datatype == 'EMISSION_FACTOR'
                "
              >
                {{ locale('locale_key.pages.emission.pre_determine_factors_here') }}
              </div>

              <div class="options" *ngIf="isCurrentStateEditing()">
                <div
                  class="option user"
                  (click)="changeDatapointProvidedBy('user')"
                  *ngIf="selectedDatapoint.datatype == 'NUMERIC'"
                  [class.option_selected]="
                    selectedDatapoint.providedBy == 'user' && selectedDatapoint.datatype == 'NUMERIC'
                  "
                >
                  {{ locale('locale_key.pages.data_category.entered_by_users') }}
                </div>
                <div
                  class="option calculation"
                  (click)="changeDatapointProvidedBy('calculation')"
                  *ngIf="selectedDatapoint.datatype == 'NUMERIC'"
                  [class.option_selected]="selectedDatapoint.providedBy == 'calculation'"
                >
                  {{ locale('locale_key.pages.data_category.calculated_by_system') }}
                </div>
                <div
                  class="option user"
                  (click)="changeDatapointProvidedBy('user')"
                  *ngIf="selectedDatapoint.datatype == 'EMISSION_FACTOR'"
                  [class.option_selected]="
                    selectedDatapoint.providedBy == 'user' && selectedDatapoint.datatype == 'EMISSION_FACTOR'
                  "
                >
                  {{ locale('locale_key.pages.emission.added_when_entering_data') }}
                </div>
                <div
                  class="option user"
                  (click)="changeDatapointProvidedBy('pre_determined')"
                  *ngIf="selectedDatapoint.datatype == 'EMISSION_FACTOR'"
                  [class.option_selected]="
                    selectedDatapoint.providedBy == 'pre_determined' && selectedDatapoint.datatype == 'EMISSION_FACTOR'
                  "
                >
                  {{ locale('locale_key.pages.emission.pre_determine_factors_here') }}
                </div>
              </div>

              <calculation-builder
                *ngIf="selectedDatapoint.providedBy == 'calculation' && selectedDatapoint.datatype == 'NUMERIC'"
                [buildMode]="'calculation'"
                [showEditMode]="isCurrentStateEditing()"
                [selectedDataCategory]="selectedDataCategory"
                [selectedDatapoint]="selectedDatapoint"
                (updateHasCalculationErrors)="updateHasCalculationErrors($event)"
                [showCalculationErrors]="showCalculationErrors"
                [isEditMode]="isCurrentStateEditing()"
              ></calculation-builder>

              <calculation-builder
                *ngIf="
                  selectedDatapoint.providedBy == 'pre_determined' && selectedDatapoint.datatype == 'EMISSION_FACTOR'
                "
                [buildMode]="'emission_factor'"
                [showEditMode]="isCurrentStateEditing()"
                [selectedDataCategory]="selectedDataCategory"
                [selectedDatapoint]="selectedDatapoint"
                (updateHasCalculationErrors)="updateHasCalculationErrors($event)"
                [showCalculationErrors]="showCalculationErrors"
                [showEmissionFactorErrors]="showEmissionFactorErrors"
                [emissionFactorErrorMsgs]="emissionFactorErrorMsgs"
                [isEditMode]="isCurrentStateEditing()"
              ></calculation-builder>

              <div
                class="emission_factor_measurement_types"
                *ngIf="
                  isCurrentStateEditing() &&
                  selectedDatapoint.providedBy == 'user' &&
                  selectedDatapoint.datatype == 'EMISSION_FACTOR'
                "
              >
                <div class="heading">
                  {{ locale('locale_key.pages.data_category.specify_acceptable_measurement_types') }}
                  <i class="las la-info-circle"></i>
                </div>

                <div class="measurement-type-inputs">
                  <select
                    class="measurement-type-input"
                    (change)="updateMeasurementTypeInput()"
                    [(ngModel)]="conversionFactorMeasurementTypeInput"
                  >
                    <option value="" disabled selected>
                      {{ locale('locale_key.pages.data_category.select_conversion_factor.measurement_type') }}
                    </option>
                    <ng-container *ngFor="let measurementType of getMeasurementTypes()">
                      <option [value]="measurementType.key">
                        {{ measurementType.name }}
                        <span *ngIf="measurementType.defaultUnitName">
                          (e.g. {{ measurementType.defaultUnitName }})
                        </span>
                      </option>
                    </ng-container>
                  </select>

                  <span class="per mt-3 mb-3">per</span>

                  <select
                    class="measurement-type-input"
                    (change)="updateMeasurementTypeInput()"
                    [(ngModel)]="sourceMeasurementTypeInput"
                  >
                    <option value="" disabled selected>
                      {{ locale('locale_key.pages.data_category.select_source_factor.measurement_type') }}
                    </option>
                    <ng-container *ngFor="let measurementType of getMeasurementTypes()">
                      <option [value]="measurementType.key">
                        {{ measurementType.name }}
                        <span *ngIf="measurementType.defaultUnitName">
                          (e.g. {{ measurementType.defaultUnitName }})
                        </span>
                      </option>
                    </ng-container>
                  </select>
                </div>

                <div class="error_msgs" *ngIf="showEmissionFactorErrors">
                  <ul>
                    <li *ngFor="let errorMsg of emissionFactorErrorMsgs">{{ errorMsg }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<div class="d-flex data-categories-page">
  <!-- FLOATING CONTROLS -->
  <div class="controls draft_controls" *ngIf="isCurrentStateViewing() && hasDraft">
    <svg
      class="has_draft_icon"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M10.2898 4.35996L1.81978 18.5C1.64514 18.8024 1.55274 19.1453 1.55177 19.4945C1.55079 19.8437 1.64127 20.1871 1.8142 20.4905C1.98714 20.7939 2.2365 21.0467 2.53748 21.2238C2.83847 21.4009 3.18058 21.4961 3.52978 21.5H20.4698C20.819 21.4961 21.1611 21.4009 21.4621 21.2238C21.7631 21.0467 22.0124 20.7939 22.1854 20.4905C22.3583 20.1871 22.4488 19.8437 22.4478 19.4945C22.4468 19.1453 22.3544 18.8024 22.1798 18.5L13.7098 4.35996C13.5315 4.06607 13.2805 3.82308 12.981 3.65444C12.6814 3.48581 12.3435 3.39722 11.9998 3.39722C11.656 3.39722 11.3181 3.48581 11.0186 3.65444C10.7191 3.82308 10.468 4.06607 10.2898 4.35996V4.35996Z"
        stroke="#E4963D"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M12 9.5V13.5" stroke="#E4963D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12 17.5H12.01" stroke="#E4963D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <div class="heading">{{ locale('locale_key.pages.data_category.edited_version_saved_as_draft.info') }}</div>
    <div class="control edit_draft" (click)="editDraft()" style="width: fit-content">
      {{ locale('locale_key.pages.data_category.edit_draft') }}
    </div>
  </div>

  <div class="controls save_controls" *ngIf="currentState === 'editing'">
    <div *ngIf="!savingInProgress" class="control cancel" (click)="startCancelEditingDataCategories()">
      {{ locale('locale_key.general.buttons.cancel') }}
    </div>
    <div
      *ngIf="!savingInProgress"
      class="control save_as_draft"
      (click)="!isActionDatapointDisabled() && startSaveAsDraft()"
      [ngClass]="{ disabled: isActionDatapointDisabled() }"
    >
      {{ locale('locale_key.general.buttons.save_draft') }}
    </div>
    <div
      *ngIf="!savingInProgress"
      class="control save"
      (click)="!isActionDatapointDisabled() && saveDataCategories()"
      [ngClass]="{ disabled: isActionDatapointDisabled() }"
    >
      {{ locale('locale_key.pages.data_category.button.save') }}
    </div>

    <div class="ball-pulse loader-primary" *ngIf="savingInProgress">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>

  <div class="controls save_successful_msg" *ngIf="showSaveSuccessfulMsg">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <g clip-path="url(#clip0_4480_3023)">
        <path
          d="M11.0089 19.0089L11.0089 19.0089L19.6339 10.3839C20.122 9.89576 20.122 9.10429 19.6339 8.61615L18.5733 7.55554C18.0851 7.0673 17.2936 7.06734 16.8055 7.55551L10.125 14.2359L7.19454 11.3055C6.70638 10.8173 5.91493 10.8174 5.42677 11.3054L5.42674 11.3055L4.3661 12.3661L4.71966 12.7197L4.3661 12.3661C3.87797 12.8542 3.87797 13.6457 4.3661 14.1338L4.71966 13.7803L4.3661 14.1339L9.2411 19.0089C9.72928 19.497 10.5207 19.497 11.0089 19.0089ZM23.125 12.5C23.125 18.6442 18.1442 23.625 12 23.625C5.85581 23.625 0.875 18.6442 0.875 12.5C0.875 6.35581 5.85581 1.375 12 1.375C18.1442 1.375 23.125 6.35581 23.125 12.5Z"
          fill="#82C19D"
          stroke="#68C781"
        />
      </g>
      <defs>
        <clipPath id="clip0_4480_3023">
          <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
    {{ locale('locale_key.pages.data_category.changes_saved_successfully') }}
  </div>

  <!-- MODALS -->
  <ng-template #brokenCalculationsModal>
    <div class="modal-header" style="border-bottom: none">
      <h4 class="modal-title mb-0 pb-0 ms-2 mb-0">
        <i
          class="la la-exclamation-circle"
          style="color: var(--warning-color); font-size: 1.5rem; position: relative; top: 2px"
        ></i>
        {{ slocale('Broken Calculations') }}
      </h4>
      <button
        type="button"
        class="btn close"
        *ngIf="!isLoading"
        (click)="closeModal({ modal: 'brokenCalculationsModal' })"
      >
        <span aria-hidden="true" class="black" style="font-size: 2rem; font-weight: 300">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="table-responsive">
        <table class="table table-sm">
          <thead>
            <tr>
              <th>#</th>
              <th>Data category</th>
              <th>Calculating datapoint</th>
              <th>Missing Referenced datapoint</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let dp of brokenCalculationsDatapoints; let i = index">
              <td>{{ i + 1 }}</td>
              <td>{{ dp.category }}</td>
              <td>{{ dp.calculatingDp?.label.en }}</td>
              <td>{{ dp.referenceDp?.label.en }}</td>
              <td>
                <button
                  type="button"
                  class="btn primary-button"
                  (click)="
                    closeModal({ modal: 'brokenCalculationsModal' });
                    viewBrokenCalculationsDatapoint(dp.entity, dp.calculatingDp)
                  "
                >
                  {{ slocale('View') }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn primary-button custom-button"
        data-dismiss="modal"
        (click)="closeModal({ modal: 'brokenCalculationsModal' })"
      >
        {{ slocale('Close') }}
      </button>
    </div>
  </ng-template>
  <ng-template #editDatapointModal>
    <div class="edit_category_modal">
      <div
        class="header"
        style="display: flex; align-items: center; padding: 0px 36px; height: 60px; background: #59bccf"
      >
        <div class="icon"></div>
        <div class="label custom-label">
          {{ resolveString(selectedDatapoint.label) }}
        </div>
      </div>
      <div
        class="header"
        style="display: flex; align-items: center; padding: 0px 36px; height: 60px; background: #e6e6e6"
      >
        <div class="icon"></div>
        <div class="label">
          {{ locale('locale_key.pages.data_category.description.colon') }}
          {{ selectedDatapoint.description[this.activeLanguage.code] }}
        </div>
      </div>
      <div class="body">
        <div class="accordion accordion-flush">
          <div class="accordion-item">
            <div
              class="accordion-header"
              style="display: flex; align-items: center; padding-left: 36px; border-bottom: 1px solid #d3d3d3"
            >
              <div class="row w-100 m-0">
                <div class="row w-100 m-0 align-items-center">
                  <div class="col-3">
                    <div class="d-flex flex-row align-items-center">
                      <i class="fs-4 la la-language"></i>
                      <span class="edit_name_label p-2">
                        {{ locale('locale_key.pages.data_categories.translations') }}
                      </span>
                      <i
                        class="fs-4 la la-info-circle info-popover-arrow"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      ></i>
                      <div class="dropdown-menu arrow p-2 info-popover">
                        <p>{{ locale('locale_key.pages.data_category.translation_can_be_added.message') }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-5">
                    <div
                      *ngIf="isGlobalAttributeTaxonomy()"
                      class="d-flex flex-row align-items-center clickable-div"
                      (click)="applyAllTranslations(false)"
                    >
                      <i class="fs-4 las la-undo-alt edit_control undo"></i>
                      <span>{{ locale('locale_key.pages.data_category.apply_suggested_translation.message') }}</span>
                    </div>
                  </div>
                  <div [class.disabled]="isDpRemoveAllTranslationDisabled()" class="col-4">
                    <div class="d-flex flex-row align-items-center clickable-div" (click)="showDpConfirmationModal()">
                      <i class="fs-4 las la-trash-alt"></i>
                      <span>{{ locale('locale_key.pages.data_categories.remove_all_translations') }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#edit_datapoint_accordion"
                style="flex: 1; background-color: white; box-shadow: none"
              ></div>
            </div>
            <div id="edit_datapoint_accordion" class="accordion-collapse collapse show">
              <div class="row w-100 py-2">
                <div class="col-1"></div>
                <div class="col-2">
                  <div class="me-3 mb-1 mt-2" style="width: 150px">
                    <i class="fs-4 fi fi-us" style="margin-right: 12px"></i>
                  </div>
                </div>
                <div class="col-9 d-flex align-items-center">
                  <div class="row w-100">
                    <div class="col-9">
                      <input
                        class="edit_category_name_input w-100"
                        [(ngModel)]="editingDatapointEnLabelDesc"
                        type="text"
                        [formControl]="datapointForm1.get('shortNameEnText')"
                        required
                        [ngClass]="{
                          'is-invalid':
                            datapointForm1.get('shortNameEnText').errors &&
                            (datapointForm1.get('shortNameEnText').touched ||
                              datapointForm1.get('shortNameEnText').dirty)
                        }"
                      />
                      <div
                        *ngIf="
                          datapointForm1.get('shortNameEnText').invalid &&
                          (datapointForm1.get('shortNameEnText').dirty || datapointForm1.get('shortNameEnText').touched)
                        "
                        class="alert alert-warning d-flex flex-row border-1 mt-2 p-2"
                      >
                        <span *ngIf="datapointForm1.get('shortNameEnText').errors.required">
                          {{ locale('locale_key.pages.data_category.input_required.message') }}
                        </span>
                        <span *ngIf="datapointForm1.get('shortNameEnText').errors.pattern">
                          {{ locale('locale_key.pages.data_category.data_point.validation.message') }}
                        </span>
                        <span *ngIf="datapointForm1.get('shortNameEnText').errors.duplicateDatapointName">
                          {{ locale('locale_key.pages.data_category.label.duplicate_name.message') }}
                        </span>
                      </div>
                    </div>
                    <div class="col-3 pe-0 pt-2">
                      <span
                        class="edit_category_name_mandatory_hint"
                        style="
                          color: var(--grey-black-500, #9799a5);
                          font-family: Open Sans;
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                        "
                      >
                        {{ locale('locale_key.pages.data_category.cannot_be_removed.validation.message') }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngFor="let translation of getDatapointTranslations(); let i = index" class="row w-100 p-1">
                <div class="col-3">
                  <div id="lang-dropdown" class="btn-group me-3 mb-1 mt-2" style="width: 150px; margin-left: 8px">
                    <button
                      [ngClass]="translation.isNew ? 'btn dropdown-toggle' : 'btn'"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="true"
                    >
                      <span *ngIf="translation.language">
                        <i class="fs-4 {{ translation.language.icon }} me-2"></i>
                        <span *ngIf="translation.isNew">{{ translation.language.label }}</span>
                      </span>
                    </button>
                    <div class="dropdown-menu">
                      <ng-container *ngFor="let language of availableLanguages">
                        <a
                          class="dropdown-item m-0 px-3"
                          style="color: var(--main-dark-color); cursor: pointer"
                          *ngIf="!isLanguageSelected(language, false)"
                          (click)="setTranslationLanguage(translation, language, false)"
                        >
                          <i class="{{ language.icon }} me-2"></i>
                          {{ language.label }}
                        </a>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="col-7 d-flex align-items-center clickable-div pe-2">
                  <input
                    [(ngModel)]="translation.text"
                    class="edit_category_name_input"
                    type="text"
                    (input)="checkDatapointTranslationDuplicate(translation)"
                    [placeholder]="'Type ' + translation.language.label + ' name'"
                    [ngClass]="{ 'is-invalid': hasDatapointDuplicateErrorOf(translation) }"
                  />
                  <i (click)="deleteDatapointTranslation(i)" class="fs-4 la la-trash-alt edit_control delete"></i>
                </div>
                <div class="col-3"></div>
                <div class="col-7 d-flex align-items-center clickable-div pe-5">
                  <div
                    *ngIf="hasDatapointDuplicateErrorOf(translation)"
                    class="alert alert-warning d-flex flex-row border-1 mt-2 p-2"
                  >
                    <span>{{ locale('locale_key.pages.data_category.label.duplicate_name.message') }}</span>
                  </div>
                </div>
              </div>
              <div class="accordion-body" style="padding-left: 36px; display: flex; align-items: center">
                <div class="btn border border-secondary" type="button" (click)="addDatapointTranslation()">
                  <i class="icon las la-plus"></i>
                  <span class="add_label">{{ locale('locale_key.pages.data_categories.add_translations') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer" style="border-top: 1px solid #d3d3d3">
        <div class="controls" style="display: flex; align-items: center; justify-content: flex-end; padding: 10px 18px">
          <div
            (click)="cancelEditDatapoint()"
            class="cancel_control"
            style="
              display: flex;
              width: 120px;
              height: 35px;
              padding: 10px 16px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              flex-shrink: 0;
              border-radius: 3px;
              border: 1px solid #e5e5e5;
              background: #fff;
              color: #374659;
              cursor: pointer;
            "
          >
            {{ locale('locale_key.general.buttons.cancel') }}
          </div>
          <button
            (click)="confirmEditDatapoint()"
            class="done_control"
            [disabled]="datapointForm1.invalid || hasDatapointDuplicateError"
            style="
              display: flex;
              width: 120px;
              height: 35px;
              padding: 10px 16px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              flex-shrink: 0;
              color: #fff;
              background: #374659;
              margin-left: 10px;
              cursor: pointer;
              /* Add disabled styles */
              opacity: 1;
              pointer-events: auto;
            "
            [ngStyle]="{
              opacity: datapointForm1.invalid || hasDatapointDuplicateError ? '0.5' : '1',
              'pointer-events': datapointForm1.invalid || hasDatapointDuplicateError ? 'none' : 'auto'
            }"
          >
            {{ locale('locale_key.pages.data_category.done') }}
          </button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #editCategoryModal>
    <div class="edit_category_modal">
      <div
        class="header"
        style="display: flex; align-items: center; padding: 0px 36px; height: 60px; background: #e6e6e6"
      >
        <div class="title">{{ locale('locale_key.pages.datahub.data_entries.accordion.edit') }}&nbsp;</div>
        <div class="icon">
          <span class="fs-4" [ngClass]="getEditCategoryModalIcon()"></span>
        </div>
        <div class="label fw-bold">
          <span>{{ getEditCategoryModalTitle() }}</span>
          <span class="edit-category-font-color">{{ getEditCategoryModalTitleLastString() }}</span>
        </div>
      </div>
      <div class="body">
        <div class="accordion accordion-flush">
          <div class="accordion-item">
            <div
              class="accordion-header"
              style="display: flex; align-items: center; padding-left: 36px; border-bottom: 1px solid #d3d3d3"
            >
              <div class="edit_name_header d-flex align-items-center">
                <span class="edit_name_label p-2">{{ locale('locale_key.pages.data_category.edit_name') }}</span>
                <i
                  class="fs-4 la la-info-circle info-popover-arrow"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                ></i>
                <div class="dropdown-menu arrow p-2 info-popover">
                  <p>{{ locale('locale_key.pages.data_category.here_you_can_edit_datapoint.message') }}</p>
                </div>
              </div>
              <div
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#edit_name_accordion"
                style="flex: 1; background-color: white; box-shadow: none"
              ></div>
            </div>
            <div id="edit_name_accordion" class="accordion-collapse collapse show">
              <div class="accordion-body" style="padding-left: 36px; display: flex; align-items: center">
                <div class="w-100">
                  <div *ngIf="!isEditingDisplayLangDataCategoryDeleted" class="d-flex flex-row align-items-center">
                    <i [class]="checkCategoryEnglishAndGetFlag()" style="margin-right: 12px"></i>
                    <input
                      id="editCategoryInputText"
                      class="edit_category_name_input w-100"
                      [(ngModel)]="editingCategoryLabel"
                      type="text"
                      [formControl]="categoryFormGroup.get('categoryNameText')"
                      required
                      [ngClass]="{
                        'is-invalid':
                          categoryFormGroup.get('categoryNameText').errors &&
                          (categoryFormGroup.get('categoryNameText').touched ||
                            categoryFormGroup.get('categoryNameText').dirty)
                      }"
                    />
                    <span
                      *ngIf="isEditingCategoryEnglish()"
                      class="edit_category_name_mandatory_hint"
                      style="
                        color: var(--grey-black-500, #9799a5);
                        font-family: Open Sans;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                      "
                    >
                      {{ locale('locale_key.pages.data_category.mandatory') }}
                    </span>
                    <div *ngIf="!isEditingCategoryEnglish()" class="clickable-div">
                      <i
                        *ngIf="isGlobalTaxonomy()"
                        [ngClass]="{ disabled: disabledUndoDataCategoryTranslation() }"
                        (click)="disabledUndoDataCategoryTranslation() ? null : undoDataCategoryTranslation()"
                        class="fs-4 las la-undo-alt edit_control undo"
                      ></i>
                      <i
                        (click)="removeDataCategoryTranslation()"
                        class="fs-4 la la-trash-alt edit_control delete px-4"
                      ></i>
                    </div>
                  </div>
                  <div
                    *ngIf="
                      categoryFormGroup.get('categoryNameText').invalid &&
                      (categoryFormGroup.get('categoryNameText').dirty ||
                        categoryFormGroup.get('categoryNameText').touched)
                    "
                    class="alert alert-warning d-flex flex-row border-1 mt-2 p-2"
                  >
                    <span *ngIf="categoryFormGroup.get('categoryNameText').errors.required">
                      {{ locale('locale_key.pages.data_categories.editing.data_caetegory.english.tooltip.message') }}
                    </span>
                    <span *ngIf="categoryFormGroup.get('categoryNameText').errors.pattern">
                      {{ locale('locale_key.pages.data_categories.editing.data_caetegory.validation.tooltip.message') }}
                    </span>
                    <span *ngIf="categoryFormGroup.get('categoryNameText').errors.duplicateCategoryName">
                      {{ locale('locale_key.pages.data_category.data_category.name_already_exists') }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion accordion-flush">
          <div class="accordion-item">
            <div
              class="accordion-header"
              style="display: flex; align-items: center; padding-left: 36px; border-bottom: 1px solid #d3d3d3"
            >
              <div *ngIf="showDetailsDataCategoryAccordion" class="d-flex flex-row align-items-center">
                <span class="edit_name_label p-2">{{ locale('locale_key.pages.data_categories.translations') }}</span>
                <i
                  class="fs-4 la la-info-circle info-popover-arrow"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                ></i>
                <div class="dropdown-menu arrow p-2 info-popover">
                  <p>{{ locale('locale_key.pages.data_category.translation_can_be_in_all_languages.message') }}</p>
                </div>
              </div>
              <div *ngIf="!showDetailsDataCategoryAccordion" class="row w-100 m-0">
                <div class="row w-100 m-0 align-items-center">
                  <div class="col-3">
                    <div class="d-flex flex-row align-items-center">
                      <span class="edit_name_label p-2">
                        {{ locale('locale_key.pages.data_categories.translations') }}
                      </span>
                      <i
                        class="fs-4 la la-info-circle info-popover-arrow"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      ></i>
                      <div class="dropdown-menu arrow p-2 info-popover">
                        <p>
                          {{ locale('locale_key.pages.data_category.translation_can_be_in_all_languages.message') }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-5">
                    <div
                      class="d-flex flex-row align-items-center clickable-div"
                      *ngIf="isGlobalTaxonomy()"
                      (click)="applyAllTranslations(true)"
                    >
                      <i class="fs-4 las la-undo-alt edit_control undo"></i>
                      <span>{{ locale('locale_key.pages.data_category.apply_suggested_translation.message') }}</span>
                    </div>
                  </div>
                  <div [class.disabled]="isDisabledTranslation" class="col-4">
                    <div
                      class="d-flex flex-row align-items-center clickable-div"
                      (click)="!isDisabledTranslation && showConfirmationModal()"
                    >
                      <i class="fs-4 las la-trash-alt"></i>
                      <span>{{ locale('locale_key.pages.data_categories.remove_all_translations') }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                (click)="showDetailsDataCategoryAccordion = !showDetailsDataCategoryAccordion"
                data-bs-target="#edit_dataCategory_accordion"
                style="flex: 1; background-color: white; box-shadow: none"
              ></div>
            </div>
            <div id="edit_dataCategory_accordion" class="accordion-collapse collapse show">
              <div *ngFor="let translation of categoryTranslations; let i = index" class="row w-100 m-2">
                <div class="col-3">
                  <div id="lang-dropdown" class="btn-group me-3 mb-1 mt-2" style="width: 150px">
                    <button
                      class="btn dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="true"
                    >
                      <span *ngIf="translation.language">
                        <i class="{{ translation.language.icon }} me-2"></i>
                        {{ translation.language.label }}
                      </span>
                    </button>
                    <div class="dropdown-menu">
                      <ng-container *ngFor="let language of availableLanguages">
                        <a
                          class="dropdown-item m-0 px-3"
                          style="color: var(--main-dark-color); cursor: pointer"
                          *ngIf="!isLanguageSelected(language, true)"
                          (click)="setTranslationLanguage(translation, language, true)"
                        >
                          <i class="{{ language.icon }} me-2"></i>
                          {{ language.label }}
                        </a>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div *ngIf="translation.language.code !== 'en'" class="d-flex align-items-center col-8">
                  <!-- other languages transaltion -->
                  <div class="d-flex flex-row w-100 align-items-center me-4 pe-1">
                    <input
                      [(ngModel)]="translation.text"
                      class="edit_category_name_input"
                      type="text"
                      [placeholder]="'Type ' + translation.language.label + ' name'"
                    />
                    <div class="clickable-div">
                      <i
                        *ngIf="isGlobalTaxonomy()"
                        [ngClass]="{ disabled: disabledUndoTranslation(translation) }"
                        (click)="disabledUndoTranslation(translation) ? null : undoTranslationCategory(translation)"
                        class="fs-4 las la-undo-alt edit_control undo"
                      ></i>
                      <i
                        (click)="deleteCategoryTranslation(i)"
                        class="fs-4 la la-trash-alt edit_control delete px-4"
                      ></i>
                    </div>
                  </div>
                </div>
                <!-- English transaltion -->
                <div *ngIf="translation.language.code === 'en'" class="d-flex flex-column align-items-center col-9">
                  <div class="d-flex flex-row w-100 align-items-center">
                    <input
                      [(ngModel)]="translation.text"
                      class="edit_category_name_input"
                      style="margin-right: 8px"
                      type="text"
                      [placeholder]="'Type ' + translation.language.label + ' name'"
                      [formControl]="categoryFormGroup2.get('categoryNameText')"
                      [ngClass]="{
                        'is-invalid':
                          categoryFormGroup2.get('categoryNameText').errors &&
                          (categoryFormGroup2.get('categoryNameText').touched ||
                            categoryFormGroup2.get('categoryNameText').dirty)
                      }"
                    />
                    <div class="clickable-div" *ngIf="isGlobalTaxonomy()">
                      <i
                        [ngClass]="{ disabled: disabledUndoTranslation(translation) }"
                        (click)="disabledUndoTranslation(translation) ? null : undoTranslationCategory(translation)"
                        class="fs-4 las la-undo-alt edit_control undo"
                      ></i>
                    </div>
                    <span class="p-3">
                      {{ locale('locale_key.pages.data_category.cannot_be_removed.validation.message') }}
                    </span>
                  </div>
                  <div
                    *ngIf="
                      categoryFormGroup2.get('categoryNameText').invalid &&
                      (categoryFormGroup2.get('categoryNameText').dirty ||
                        categoryFormGroup2.get('categoryNameText').touched)
                    "
                    class="alert alert-warning d-flex flex-row border-1 mt-2 p-2 me-2"
                  >
                    <span *ngIf="categoryFormGroup2.get('categoryNameText').errors.required">
                      {{ locale('locale_key.pages.data_categories.editing.data_caetegory.english.tooltip.message') }}
                    </span>
                    <span *ngIf="categoryFormGroup2.get('categoryNameText').errors.pattern">
                      {{ locale('locale_key.pages.data_categories.editing.data_caetegory.validation.tooltip.message') }}
                    </span>
                    <span *ngIf="categoryFormGroup2.get('categoryNameText').errors.duplicateCategoryName">
                      {{ locale('locale_key.pages.data_category.data_category.name_already_exists') }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="accordion-body" style="padding-left: 36px; display: flex; align-items: center">
                <div class="btn border border-secondary" type="button" (click)="addCategoryTranslation()">
                  <i class="icon las la-plus"></i>
                  <span class="add_label">{{ locale('locale_key.pages.data_categories.add_translations') }}</span>
                </div>
              </div>
              <div class="row mx-4 pt-3" *ngIf="isGlobalTaxonomy() && showSuggestedTranslationAlert()">
                <div class="col-lg-12">
                  <div
                    class="alert alert-warning-custom d-flex flex-row justify-content-center align-items-center border-1"
                  >
                    <span>{{ locale('locale_key.pages.data_category.make_sure_update_other_languages.message') }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer" style="border-top: 1px solid #d3d3d3">
        <div class="controls" style="display: flex; align-items: center; justify-content: flex-end; padding: 10px 18px">
          <div
            (click)="cancelEditCategory()"
            class="cancel_control"
            style="
              display: flex;
              width: 120px;
              height: 35px;
              padding: 10px 16px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              flex-shrink: 0;
              border-radius: 3px;
              border: 1px solid #e5e5e5;
              background: #fff;
              color: #374659;
              cursor: pointer;
            "
          >
            {{ locale('locale_key.general.buttons.cancel') }}
          </div>
          <div (click)="confirmEditCategory()" [class.disabled]="disableCategoryConfirm()" class="confirm_control">
            {{ locale('locale_key.pages.data_category.done') }}
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #deleteCategoryModal>
    <div class="delete_category_modal" style="padding: 16px">
      <div
        class="header"
        style="display: flex; justify-content: space-between; align-items: center; padding-bottom: 16px"
      >
        <div
          class="title"
          style="
            color: var(--primary-colors-primary, #374659);
            font-family: Open Sans;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          "
        >
          {{
            locale('locale_key.pages.data_category.delete_category', { label: resolveString(deletingCategory.label) })
          }}
        </div>
        <div class="close_control">
          <i
            class="las la-times"
            (click)="cancelDeleteCategory()"
            style="font-size: 24px; color: #374659; opacity: 0.3; cursor: pointer"
          ></i>
        </div>
      </div>
      <div class="body" style="padding-top: 10px; padding-bottom: 20px">
        <div
          class="message"
          style="
            color: var(--primary-colors-primary, #374659);
            font-family: Open Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          "
        >
          {{ locale('locale_key.pages.data_category.confirm_category_deletion') }}
        </div>
      </div>
      <div class="footer">
        <div class="controls" style="display: flex; align-items: center; justify-content: flex-end">
          <div
            class="cancel_control"
            (click)="cancelDeleteCategory()"
            style="
              padding: 10px 16px;
              display: flex;
              width: 120px;
              height: 35px;
              padding: 10px 16px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              border-radius: 3px;
              background: #d3d3d3;
              color: #374659;
              user-select: none;
              cursor: pointer;
            "
          >
            {{ locale('locale_key.general.buttons.cancel') }}
          </div>
          <div
            class="delete_control"
            (click)="confirmDeleteCategory()"
            style="
              margin-left: 16px;
              padding: 10px 16px;
              display: flex;
              width: 120px;
              height: 35px;
              padding: 10px 16px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              border-radius: 3px;
              background: #b8615f;
              color: #fff;
              user-select: none;
              cursor: pointer;
            "
          >
            {{ locale('locale_key.general.buttons.delete') }}
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #deleteDatapointModal>
    <div class="delete_category_modal" style="padding: 16px">
      <div
        class="header"
        style="display: flex; justify-content: space-between; align-items: center; padding-bottom: 16px"
      >
        <div
          class="title"
          style="
            color: var(--primary-colors-primary, #374659);
            font-family: Open Sans;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          "
        >
          {{
            locale('locale_key.pages.data_category.delete_datapoint', { label: resolveString(deletingDatapoint.label) })
          }}
        </div>
        <div class="close_control">
          <i
            class="las la-times"
            (click)="cancelDeleteDatapoint()"
            style="font-size: 24px; color: #374659; opacity: 0.3; cursor: pointer"
          ></i>
        </div>
      </div>
      <div class="body" style="padding-top: 10px; padding-bottom: 20px">
        <div
          class="message"
          style="
            color: var(--primary-colors-primary, #374659);
            font-family: Open Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          "
        >
          {{ locale('locale_key.pages.data_category.confirm_datapoint_deletion') }}
        </div>
      </div>
      <div class="footer">
        <div class="controls" style="display: flex; align-items: center; justify-content: flex-end">
          <div
            class="cancel_control"
            (click)="cancelDeleteDatapoint()"
            style="
              padding: 10px 16px;
              display: flex;
              width: 120px;
              height: 35px;
              padding: 10px 16px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              border-radius: 3px;
              background: #d3d3d3;
              color: #374659;
              user-select: none;
              cursor: pointer;
            "
          >
            {{ locale('locale_key.general.buttons.cancel') }}
          </div>
          <div
            class="delete_control"
            (click)="confirmDeleteDatapoint()"
            style="
              margin-left: 16px;
              padding: 10px 16px;
              display: flex;
              width: 120px;
              height: 35px;
              padding: 10px 16px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              border-radius: 3px;
              background: #b8615f;
              color: #fff;
              user-select: none;
              cursor: pointer;
            "
          >
            {{ locale('locale_key.general.buttons.delete') }}
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #cancelEditingDatacategoriesModal>
    <div class="delete_category_modal" style="padding: 16px">
      <div
        class="header"
        style="display: flex; justify-content: space-between; align-items: center; padding-bottom: 16px"
      >
        <div
          class="title"
          style="
            color: var(--primary-colors-primary, #374659);
            font-family: Open Sans;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          "
        >
          {{ locale('locale_key.pages.insight_detail.cancel') }}
        </div>
        <div class="close_control">
          <i
            class="las la-times"
            (click)="exitCancelEditingDatacategories()"
            style="font-size: 24px; color: #374659; opacity: 0.3; cursor: pointer"
          ></i>
        </div>
      </div>
      <div class="body" style="padding-top: 10px; padding-bottom: 20px">
        <div
          class="message"
          style="
            color: var(--primary-colors-primary, #374659);
            font-family: Open Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          "
        >
          {{ locale('locale_key.pages.data_categories.action_cancel.message') }}
        </div>
      </div>
      <div class="footer">
        <div class="controls" style="display: flex; align-items: center; justify-content: flex-end">
          <div
            class="cancel_control"
            (click)="exitCancelEditingDatacategories()"
            style="
              padding: 10px 16px;
              display: flex;
              width: 120px;
              height: 35px;
              padding: 10px 16px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              border-radius: 3px;
              background: #d3d3d3;
              color: #374659;
              user-select: none;
              cursor: pointer;
              color: var(--Primary-Colors-Primary, #374659);
              text-align: center;

              /* Body Reg */
              font-family: Open Sans;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-wrap: nowrap;
            "
          >
            {{ locale('locale_key.pages.data_categories.back_to_editing') }}
          </div>
          <div
            class="delete_control"
            (click)="confirmCancelEditingDatacategories()"
            style="
              margin-left: 16px;
              padding: 10px 16px;
              display: flex;
              width: 120px;
              height: 35px;
              padding: 10px 16px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              border-radius: 3px;
              background: var(--Primary-Colors-Primary, #374659);
              color: #fff;
              user-select: none;
              cursor: pointer;
            "
          >
            {{ locale('locale_key.pages.insight_detail.leave_page') }}
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #saveAsDraftModal>
    <div class="delete_category_modal" style="padding: 16px">
      <div
        class="header"
        style="display: flex; justify-content: space-between; align-items: center; padding-bottom: 16px"
      >
        <div
          class="title"
          style="
            color: var(--primary-colors-primary, #374659);
            font-family: Open Sans;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          "
        >
          {{ locale('locale_key.pages.task_portal.button.save_as_draft') }}
        </div>
        <div class="close_control">
          <i
            class="las la-times"
            (click)="cancelSaveAsDraft()"
            style="font-size: 24px; color: #374659; opacity: 0.3; cursor: pointer"
          ></i>
        </div>
      </div>
      <div class="body" style="padding-top: 10px; padding-bottom: 20px">
        <div
          class="message"
          style="
            color: var(--primary-colors-primary, #374659);
            font-family: Open Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          "
        >
          {{ locale('locale_key.pages.data_categories.modal_change.message') }}
        </div>
      </div>
      <div class="footer">
        <div class="controls" style="display: flex; align-items: center; justify-content: flex-end">
          <div
            *ngIf="!savingInProgress"
            class="cancel_control"
            (click)="cancelSaveAsDraft()"
            style="
              padding: 10px 16px;
              display: flex;
              width: 120px;
              height: 35px;
              padding: 10px 16px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              border-radius: 3px;
              background: #d3d3d3;
              color: #374659;
              user-select: none;
              cursor: pointer;
              color: var(--Primary-Colors-Primary, #374659);
              text-align: center;

              /* Body Reg */
              font-family: Open Sans;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-wrap: nowrap;
            "
          >
            {{ locale('locale_key.general.buttons.cancel') }}
          </div>
          <div
            *ngIf="!savingInProgress"
            class="delete_control"
            (click)="confirmSaveAsDraft()"
            style="
              margin-left: 16px;
              padding: 10px 16px;
              display: flex;
              width: 120px;
              height: 35px;
              padding: 10px 16px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              border-radius: 3px;
              background: var(--Primary-Colors-Primary, #374659);
              color: #fff;
              user-select: none;
              cursor: pointer;
            "
          >
            {{ locale('locale_key.button.save_as_draft') }}
          </div>
          <div class="ball-pulse loader-primary" *ngIf="savingInProgress">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <add-datapoint-dialog #addDatapointDialog [selectedDataCategory]="selectedDataCategory"></add-datapoint-dialog>
</div>

<ng-template #duplicateCategoryModal>
  <div class="main-modal-header modal-header py-0 px-3">
    <h5 class="modal-title text-white">
      {{ locale('locale_key.general.data_category.duplicate_data_category_modal.title') }}
    </h5>
    <button type="button" class="btn close" (click)="cancelDuplicateCategory()">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="border-top: none">
    <p class="bold">
      {{
        locale('locale_key.general.data_category.duplicate_data_category_modal.desc', {
          label: resolveString(selectedDataCategory.level_3.label)
        })
      }}
    </p>
    <div class="row p-1 mb-1">
      <input class="form-control mx-1" placeholder="{{ locale('locale_key.general.search') }}" #searchInModal />
    </div>
    <div class="accordion accordion-flush">
      <div
        *ngFor="let level_1 of getDataCategoriesForDuplicateDataCategoryModal(searchInModal.value); let isFirst = first"
        class="accordion-item level_1_container mb-1"
      >
        <div class="accordion-header d-flex">
          <div
            class="level_1_header d-flex align-items-center p-1"
            style="min-width: fit-content; background: var(--grey-table-header, #e6e6e6)"
          >
            <i class="icon" [class]="dcIcons[level_1.key]"></i>
            <span class="level_1_label">
              {{ resolveString(level_1.label) }}
            </span>
          </div>
          <div
            class="accordion-button"
            [class.collapsed]="false"
            type="button"
            data-bs-toggle="collapse"
            [attr.data-bs-target]="'#duplicate' + level_1.key"
            style="background: var(--grey-table-header, #e6e6e6)"
          ></div>
        </div>
        <div [id]="'duplicate' + level_1.key" class="accordion-collapse collapse" [class.show]="true">
          <div class="accordion-body">
            <div *ngFor="let level_2 of getLevel2DataCategories(level_1)" class="level_2_container">
              <div class="level_2_header">
                <input
                  type="checkbox"
                  id="{{ level_2.key }}"
                  (change)="handleDuplicateDataCategorySelection(level_2, $event)"
                />
                <label for="{{ level_2.key }}" class="ps-1 cursor level_2_label">
                  {{ resolveString(level_2.label) }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn secondary-button custom-button"
      (click)="cancelDuplicateCategory()"
      data-test="request-cancel-button"
    >
      {{ locale('locale_key.general.buttons.cancel') }}
    </button>
    <button class="btn primary-button custom-button" (click)="duplicateCategory()" data-test="request-create-button">
      {{ locale('locale_key.general.buttons.duplicate') }}
    </button>
  </div>
</ng-template>

<ng-template #deleteCategoryTranslationsModal>
  <div class="delete_category_modal" style="padding: 16px">
    <div
      class="header"
      style="display: flex; justify-content: space-between; align-items: center; padding-bottom: 16px"
    >
      <div
        class="title"
        style="
          color: var(--primary-colors-primary, #374659);
          font-family: Open Sans;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        "
      >
        {{ locale('locale_key.pages.data_categories.remove_all_translations') }}
      </div>
      <div class="close_control">
        <i
          class="las la-times"
          (click)="cancelCategoryTranslationsModal()"
          style="font-size: 24px; color: #374659; opacity: 0.3; cursor: pointer"
        ></i>
      </div>
    </div>
    <div class="body" style="padding-top: 10px; padding-bottom: 20px">
      <div
        class="message"
        style="
          color: var(--primary-colors-primary, #374659);
          font-family: Open Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        "
      >
        {{ getConfirmationModalBodyText() }}
      </div>
    </div>
    <div class="footer">
      <div class="controls" style="display: flex; align-items: center; justify-content: flex-end">
        <div
          class="cancel_control"
          (click)="cancelCategoryTranslationsModal()"
          style="
            padding: 10px 16px;
            display: flex;
            width: 120px;
            height: 35px;
            padding: 10px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 3px;
            background: #d3d3d3;
            color: #374659;
            user-select: none;
            cursor: pointer;
          "
        >
          {{ locale('locale_key.delete_confirmation_modal.button.cancel') }}
        </div>
        <div
          class="done_control"
          (click)="clearTranslations(true)"
          style="
            display: flex;
            width: 120px;
            height: 35px;
            padding: 10px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            flex-shrink: 0;
            color: #fff;
            background: #374659;
            margin-left: 10px;
            cursor: pointer;

            /* Add disabled styles */
            opacity: 1;
            pointer-events: auto;
          "
        >
          {{ locale('locale_key.pages.view_template.reset.button') }}
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #deleteDatapointTranslationsModal>
  <div class="delete_category_modal" style="padding: 16px">
    <div
      class="header"
      style="display: flex; justify-content: space-between; align-items: center; padding-bottom: 16px"
    >
      <div
        class="title"
        style="
          color: var(--primary-colors-primary, #374659);
          font-family: Open Sans;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        "
      >
        {{ locale('locale_key.pages.data_categories.remove_all_translations') }}
      </div>
      <div class="close_control">
        <i
          class="las la-times"
          (click)="cancelDatapointTranslationsModal()"
          style="font-size: 24px; color: #374659; opacity: 0.3; cursor: pointer"
        ></i>
      </div>
    </div>
    <div class="body" style="padding-top: 10px; padding-bottom: 20px">
      <div
        class="message"
        style="
          color: var(--primary-colors-primary, #374659);
          font-family: Open Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        "
      >
        {{ getConfirmationModalBodyText() }}
      </div>
    </div>
    <div class="footer">
      <div class="controls" style="display: flex; align-items: center; justify-content: flex-end">
        <div
          class="cancel_control"
          (click)="cancelDatapointTranslationsModal()"
          style="
            padding: 10px 16px;
            display: flex;
            width: 120px;
            height: 35px;
            padding: 10px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 3px;
            background: #d3d3d3;
            color: #374659;
            user-select: none;
            cursor: pointer;
          "
        >
          {{ locale('locale_key.delete_confirmation_modal.button.cancel') }}
        </div>
        <div
          class="done_control"
          (click)="clearTranslations(false)"
          style="
            display: flex;
            width: 120px;
            height: 35px;
            padding: 10px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            flex-shrink: 0;
            color: #fff;
            background: #374659;
            margin-left: 10px;
            cursor: pointer;

            /* Add disabled styles */
            opacity: 1;
            pointer-events: auto;
          "
        >
          {{ locale('locale_key.pages.view_template.reset.button') }}
        </div>
      </div>
    </div>
  </div>
</ng-template>
