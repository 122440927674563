import { Component, OnInit, OnDestroy, EventEmitter, Output, Input } from '@angular/core'
import { Subscription } from 'rxjs'
import { TaxonomyInfoSubjectFe } from 'src/app/model/subject/TaxonomyInfoSubjectFe'
import { TaxonomyInfoFe } from 'src/app/model/taxonomy/TaxonomyInfoFe'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { CsrdDefintionService } from '../../CsrdDefintionService'
import { TaxonomyUtils } from '../../taxonomy/TaxonomyUtils'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'

@Component({
  selector: 'app-csrd-taxonomy-deployment-modal',
  templateUrl: './csrd-taxonomy-deployment-modal.component.html',
  styleUrls: ['./csrd-taxonomy-deployment-modal.component.scss']
})
export class CsrdTaxonomyDeploymentModalComponent extends AbstractLanguageComponent implements OnInit, OnDestroy {
  @Output() csrdDeploymentCompleted: EventEmitter<any> = new EventEmitter<any>()
  @Output() csrdDeploymentCancelled: EventEmitter<any> = new EventEmitter<any>()

  @Input() headerTitle: any

  isCsrdDeploymentStarted = false
  isCsrdDeploymentFailed = false
  isCsrdDeploymentComplete = false

  DEPLOYMENT_STAGE = {
    NOT_STARTED: 'NOT_STARTED',
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETED: 'COMPLETED'
  }

  csrdDeploymentProgressTracker = {
    identify: this.DEPLOYMENT_STAGE.NOT_STARTED,
    draft: this.DEPLOYMENT_STAGE.NOT_STARTED,
    save: this.DEPLOYMENT_STAGE.NOT_STARTED,
    restore: this.DEPLOYMENT_STAGE.NOT_STARTED
  }
  taxonomyInfos: { depTaxonomy: TaxonomyInfoFe; newTaxonomy: TaxonomyInfoFe }
  depTaxonomySubscription: Subscription
  newTaxonomySubscription: Subscription

  constructor(
    private stateService: StateServiceFe,
    languageService: LanguageService,
    private csrdDefinitionService: CsrdDefintionService
  ) {
    super(languageService)
  }

  async ngOnInit() {
    this.depTaxonomySubscription = this.stateService.depTaxonomyInfoSubject.subscribe(
      (subject: TaxonomyInfoSubjectFe) => {
        this.taxonomyInfos.depTaxonomy = subject.taxonomyInfo
      }
    )
    this.newTaxonomySubscription = this.stateService.newTaxonomyInfoSubject.subscribe(
      (subject: TaxonomyInfoSubjectFe) => {
        this.taxonomyInfos.newTaxonomy = subject.taxonomyInfo
      }
    )

    this.taxonomyInfos = await this.stateService.getTaxonomyInfos()
    this.resetCsrdDeploymentProgressTracker()
  }

  resetCsrdDeploymentProgressTracker() {
    this.isCsrdDeploymentComplete = false
    this.isCsrdDeploymentFailed = false
    this.isCsrdDeploymentStarted = false

    this.csrdDeploymentProgressTracker.identify = this.DEPLOYMENT_STAGE.NOT_STARTED
    this.csrdDeploymentProgressTracker.draft = this.DEPLOYMENT_STAGE.NOT_STARTED
    this.csrdDeploymentProgressTracker.save = this.DEPLOYMENT_STAGE.NOT_STARTED
    this.csrdDeploymentProgressTracker.restore = this.DEPLOYMENT_STAGE.NOT_STARTED
  }

  ngOnDestroy() {
    this.depTaxonomySubscription?.unsubscribe()
    this.newTaxonomySubscription?.unsubscribe()
  }

  async confirmCsrdTaxonomyDeployment() {
    await this.deployCSRDCategories()
    if (this.isCsrdDeploymentComplete) this.csrdDeploymentCompleted.emit()
  }

  async deployCSRDCategories() {
    this.isCsrdDeploymentStarted = true

    this.csrdDeploymentProgressTracker.identify = this.DEPLOYMENT_STAGE.IN_PROGRESS
    const userdraftTaxonomy = this.taxonomyInfos.newTaxonomy

    try {
      const { newTaxonomyInfoWithCsrd, shouldRedeployCsrd } = this.csrdDefinitionService.pickCsrdTaxonomies(
        this.taxonomyInfos.depTaxonomy
      )

      if (!shouldRedeployCsrd) {
        this.csrdDeploymentProgressTracker.identify = this.DEPLOYMENT_STAGE.COMPLETED
        this.csrdDeploymentProgressTracker.draft = this.DEPLOYMENT_STAGE.COMPLETED
        this.csrdDeploymentProgressTracker.save = this.DEPLOYMENT_STAGE.COMPLETED
        this.csrdDeploymentProgressTracker.restore = this.DEPLOYMENT_STAGE.COMPLETED

        this.isCsrdDeploymentComplete = true
        return
      }

      newTaxonomyInfoWithCsrd.version = userdraftTaxonomy.version

      this.csrdDeploymentProgressTracker.identify = this.DEPLOYMENT_STAGE.COMPLETED
      this.csrdDeploymentProgressTracker.draft = this.DEPLOYMENT_STAGE.COMPLETED

      this.csrdDeploymentProgressTracker.save = this.DEPLOYMENT_STAGE.IN_PROGRESS

      await this.stateService.updateNewTaxonomyInfo(newTaxonomyInfoWithCsrd)
      await this.stateService.upgradeTaxonomyInfo()

      this.csrdDeploymentProgressTracker.save = this.DEPLOYMENT_STAGE.COMPLETED
      this.csrdDeploymentProgressTracker.restore = this.DEPLOYMENT_STAGE.IN_PROGRESS

      this.csrdDefinitionService.pickDraftTaxonomiesFromPrevious(this.taxonomyInfos.newTaxonomy, userdraftTaxonomy)

      let shouldRestoreUserDraft = TaxonomyUtils.consolidateTaxonomyDiversion(
        this.taxonomyInfos.newTaxonomy,
        this.taxonomyInfos.depTaxonomy
      )
      if (!shouldRestoreUserDraft) {
        shouldRestoreUserDraft = TaxonomyUtils.checkForDraft(this.taxonomyInfos.newTaxonomy)
      }

      if (shouldRestoreUserDraft) await this.stateService.updateNewTaxonomyInfo(this.taxonomyInfos.newTaxonomy)

      this.csrdDeploymentProgressTracker.restore = this.DEPLOYMENT_STAGE.COMPLETED
      this.isCsrdDeploymentComplete = true
    } catch {
      this.isCsrdDeploymentFailed = true
      await this.stateService.updateNewTaxonomyInfo(userdraftTaxonomy)
    }
  }

  closeCsrdDeploymentModal() {
    this.csrdDeploymentCancelled.emit()
  }
}
