import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { Subject } from 'rxjs'
import { READ_STATUS, REQUEST_TASK_STATUS } from 'src/app/model/data-suppliers/request/RequestFe'
import { QuestionAttachmentFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionAttachmentFe'
import { QuestionDataTableFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionDataTableFe'
import { QuestionDateFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionDateFe'
import { QuestionFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionFe'
import { QuestionMultipleChoiceFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionMultipleChoiceFe'
import { QuestionNumberFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionNumberFe'
import { QuestionSingleChoiceFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionSingleChoiceFe'
import { QUESTION_TYPE } from 'src/app/model/data-suppliers/request/questionnaire/QuestionTypeFe'
import { PredefinedDateOptionFe } from 'src/app/model/data-suppliers/request/questionnaire/predefinedoption/PredefinedDateOptionFe'
import { PredefinedEmissionFactorOptionFe } from 'src/app/model/data-suppliers/request/questionnaire/predefinedoption/PredefinedEmissionFactorOptionFe'
import { PredefinedNumberOptionFe } from 'src/app/model/data-suppliers/request/questionnaire/predefinedoption/PredefinedNumberOptionFe'
import { TaskFe } from 'src/app/model/data-suppliers/task/TaskFe'
import { ActivityTypeFe } from 'src/app/model/data-suppliers/timeline/ActivityTypeFe'
import { SubmitAnswersActivityFe } from 'src/app/model/data-suppliers/timeline/SubmitAnswersActivityFe'
import { SubmitAnswersActivity_AddFe } from 'src/app/model/data-suppliers/timeline/add/SubmitAnswersActivity_AddFe'
import { AnswerAttachment_AddFe } from 'src/app/model/data-suppliers/timeline/add/answer/AnswerAttachment_AddFe'
import { AnswerDataTable_AddFe } from 'src/app/model/data-suppliers/timeline/add/answer/AnswerDataTable_AddFe'
import { AnswerMultipleChoice_AddFe } from 'src/app/model/data-suppliers/timeline/add/answer/AnswerMultipleChoice_AddFe'
import { Answer_AddFe } from 'src/app/model/data-suppliers/timeline/add/answer/Answer_AddFe'
import { AnswerAttachmentFe } from 'src/app/model/data-suppliers/timeline/answer/AnswerAttachmentFe'
import { AnswerDataTableFe } from 'src/app/model/data-suppliers/timeline/answer/AnswerDataTableFe'
import { AnswerFe } from 'src/app/model/data-suppliers/timeline/answer/AnswerFe'
import { AnswerTypeConversionFe } from 'src/app/model/data-suppliers/timeline/answer/AnswerTypeConversionFe'
import { AttachmentFe } from 'src/app/model/details/AttachmentFe'
import { AbstractEmissionFactorFe } from 'src/app/model/emissions/AbstractEmissionFactorFe'
import { FileDataFe } from 'src/app/model/file/FileDataFe'
import { FileTypeFe } from 'src/app/model/file/FileTypeFe'
import { RowFe } from 'src/app/model/schema/RowFe'
import { RowStatusFe } from 'src/app/model/schema/RowStatusFe'
import { TableDataFe } from 'src/app/model/schema/TableDataFe'
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize'
import { AlertServiceFe } from 'src/app/services/AlertServiceFe'
import { DisplayServiceFe } from 'src/app/services/DisplayServiceFe'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { RequestServiceFe } from 'src/app/services/RequestServiceFe'
import { ResponsiveService } from 'src/app/services/ResponsiveService'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { TableStorageServiceFe } from 'src/app/services/TableStorageServiceFe'
import { DateUtil } from 'src/app/utils/DateUtil'
import { IdUtil } from 'src/app/utils/IdUtil'
import { InputFilterUtil } from 'src/app/utils/InputFilterUtil'
import { ErrorsFe } from 'src/app/utils/KNOWN_ERRORS'
import { UnitUtilFe } from 'src/app/utils/UnitUtilFe'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { TaskDataGridIntegrationService } from '../../data-suppliers/data-supplier-task/TaskDataGridIntegrationService'
import { DataGridTableMode } from '../../projects/data-grid-ui/model/DataGridTableMode'
import { DataGridServiceInterface } from '../../projects/data-grid-ui/service/DataGridServiceInterface'
import { ImportedDataService } from '../../projects/data-grid-ui/service/ImportDataService'
import { RequestEntryDataGridIntegrationService } from '../../setup/RequestEntryDataGridIntegrationService'
import { UnitFe } from '../../unit-systems/model/UnitFe'
import { groupUnitsByMeasurementTypeAndSystem } from '../../unit-systems/model/utils'
import { NoUnit } from '../../unit-systems/unit-selector/unit-selector.component'
import { UnitServiceFe } from 'src/app/services/UnitServiceFe'
import { DatePipe } from '@angular/common'
import { QuestionEmissionFactorFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionEmissionFactorFe'
import { SectionFe } from 'src/app/model/data-suppliers/request/questionnaire/SectionFe'
import { UNIT, UNIT_VALUE } from 'src/app/utils/ConstantUtil'

@Component({
  selector: 'answer',
  templateUrl: './answer.component.html',
  styleUrls: ['./answer.component.scss']
})
export class AnswerComponent extends AbstractLanguageComponent implements OnInit, OnChanges {
  inProgress: boolean = true
  isChatOpen: boolean = false
  viewNo = 1
  message: string = null
  taskHistory = true
  detailsData: any
  changeDeadlineOnHover: boolean
  loadingInfo = false
  loadingInProgress = false
  draftLoadingInProgress = false
  doQView: number = 1
  progress: any
  isSent: boolean
  submitted: boolean = false
  inputNumberHasError: boolean = false
  isInvalidDateFromState: boolean = false
  isInvalidDateToState: boolean = false
  showEF: boolean = false
  datePlaceholder: string = 'dd MMM, yyyy'

  @Input() task: TaskFe
  @Input() isFromInboxPage = false
  @Input() answerActivity: SubmitAnswersActivityFe = null
  @Input() showQuestionnaireIcon: boolean = true
  @Output() endAnswerMode: Subject<boolean> = new Subject()
  @Output() answerModeStarted: Subject<boolean> = new Subject()
  @Output() openQuestionsView = new EventEmitter<void>()
  @Output() openQuestionnaire = new EventEmitter<void>()
  @ViewChild('scrollMe') private myScrollContainer: ElementRef
  selectedPrevTask: TaskFe
  tasks: TaskFe[]
  questions: QuestionFe[]
  questionIdToIndexMap: Map<string, number> = new Map()
  activeQuestion: QuestionFe
  activeQuestionIndex: number
  isReviewedOnce = false
  questionsRefNumber: Map<string, string> = new Map()
  isQuestionnaireReadyForSubmission: boolean = false

  requiredQuestionMessage = this.locale('locale_key.general.validation_message.question_required')
  dataTableNotValidMessage = this.locale('locale_key.general.validation_message.incorrect_data_in_table')

  isFileExtractor = false
  public toCopyTableService: TaskDataGridIntegrationService
  url = ''
  initCacheInProgress: boolean
  menuCollapsed: boolean
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  pageToolbar = [
    ,
    [
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.info'),
        longLabel: this.locale('locale_key.general.toolbar.button.show_quick_tips'),
        tooltip: this.locale('locale_key.general.toolbar.button.show_quick_tips'),
        icon: 'la la-info',
        actionName: 'toggle_quick_tips',
        visible: () => true,
        disabled: false
      }
    ]
  ]

  isSecondView: boolean = false
  answerActivityOfPrevTask: SubmitAnswersActivityFe

  isCollapse: boolean
  isLoading: any
  units = []
  customUnits = []
  unitsByMeasurementType = []
  selectedAnswer: boolean = false

  recentlyAddedCustomOptions: any[] = []
  selectedUnitContainer: { selectedUnit?: UnitFe } = {}

  followupsExpandedInPreview: { [key: string]: boolean } = {}
  followupsExpandedInAnswerView: { [key: string]: boolean } = {}

  isFormView: boolean = false
  isSMView: boolean = false

  constructor(
    private modalRef: BsModalRef,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    public stateService: StateServiceFe,
    private displayService: DisplayServiceFe,
    public tableStorageService: TableStorageServiceFe,
    private alertService: AlertServiceFe,
    private domSanitizer: DomSanitizer,
    private importedDataService: ImportedDataService,
    private ErrorsFe: ErrorsFe,
    languageService: LanguageService,
    private responsive: ResponsiveService,
    public requestService: RequestServiceFe,
    private datePipe: DatePipe,
    private unitService: UnitServiceFe
  ) {
    super(languageService)
    this.url = window.location.href
    this.initCacheInProgress = this.stateService.initCacheInProgress
    this.stateService.initCacheSubject.subscribe((initCacheInProgress) => {
      this.initCacheInProgress = initCacheInProgress
    })

    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    })

    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })
    this.screenSize = responsive.currentScreenWidthSize
    this.stateService.unitsUpdated.subscribe(async (units) => {
      await this.loadUnits()
    })
    // this.showFollowUpQuestions = new Array(this.questions.length).fill(false)
  }

  ngOnInit(): void {
    this.inProgress = true
    this.fillAnswer()
    this.loadUnits()
    this.renderNewData()
    this.displayService.closeTips()
    this.inProgress = false
  }

  async ngOnChanges(): Promise<void> {
    this.fillAnswer()
    await this.loadUnits()
    this.renderNewData()
    this.displayService.closeTips()
  }

  async loadUnits() {
    this.units = await this.stateService.getUnits(false, this.task.managementCompanyId)
    const unitsByMeasurementType = groupUnitsByMeasurementTypeAndSystem(this.units)
    this.unitsByMeasurementType = unitsByMeasurementType
    this.customUnits = this.units.filter((unit) => unit.isCustom && !unit.isStandard)
  }

  fillAnswer() {
    let acts = this.task.timelineItems.slice().reverse()
    let a = acts.find((a) => this.requestService.isSubmitAnswer(a)) as SubmitAnswersActivityFe
    this.answerActivity = a
    if (this.answerActivity) {
      this.task.questionnaire.setNewAnswers(this.answerActivity, this.task.managementCompanyId)
      this.task.questionnaire.setAnswers(this.answerActivity)
    }
  }

  async renderNewData() {
    this.tasks = await this.stateService.getTasks()
    this.task.questionnaire.setParentIds()
    this.questions = this.task.questionnaire.sections.reduce(
      (accumulator, section) => accumulator.concat(section.questions),
      []
    )
    // load to questionIdToIndex map
    this.questions.forEach((question, index) => {
      this.questionIdToIndexMap.set(question.id, index)
    })

    this.questionsRefNumber = this.task.questionnaire.getQuestionReferenceNumbers()
    this.activeQuestion = this.questions[0]
    this.activeQuestionIndex = 0

    await this.updateAndPrepareQuestions()

    this.viewNo = 1
    if (this.answerActivity) {
      this.progress = 100
    } else {
      this.progress = 0
    }
  }

  async updateAndPrepareQuestions() {
    const databaseMap = await this.stateService.getGlobalDatabseMap()

    for (const question of this.questions) {
      if (question instanceof QuestionDataTableFe) {
        if (!question.newAnswer.dataGridService) {
          question.newAnswer.dataGridService = new TaskDataGridIntegrationService(new TableDataFe())
        }
        question.setAnswerTaskDataGridService()
        if (question.newAnswer.dataStorageId) await this.setTableDraft(question)
      }

      if (question instanceof QuestionEmissionFactorFe) {
        const predefinedOptions = (question as any)?.predefinedOptions
        const hasPredefinedOptions = predefinedOptions && predefinedOptions.length > 0

        if (hasPredefinedOptions) {
          for (const op of predefinedOptions) {
            const globalDatabaseId = op.selectedEmissionFactor?.['databaseId']
            if (globalDatabaseId) {
              op.selectedEmissionFactor.relatedDatabase = databaseMap.get(globalDatabaseId)
            }
          }
        }
      }
    }
  }

  async setTableDraft(question: QuestionDataTableFe) {
    let answer = question.newAnswer
    answer.isOpenedOnce = true
    if (!answer.draftTable) {
      this.draftLoadingInProgress = true
      answer.isLoading = true
      let content = await this.stateService.downloadDataTableFile(this.task.managementCompanyId, answer.dataStorageId)
      await this.tableStorageService.convertFileIntoTable(content, async (table: TableDataFe) => {
        answer.draftTable = table
        answer.dataGridService.table.rows = table.rows
        answer.dataGridService.table.rowCount = table.rows.length
        answer.dataGridService.observer?.renderNewDataTable()
        answer.isLoading = false
      })
    } else {
      answer.dataGridService.table.rows = answer.draftTable.rows
      answer.dataGridService.table.rowCount = answer.draftTable.rows.length
      answer.dataGridService.observer?.renderNewDataTable()
      answer.isLoading = false
    }
  }

  public openModal(modalTemplateRef: TemplateRef<any>, clazz: string) {
    this.isSecondView = false
    let config = {
      ignoreBackdropClick: false,
      class: clazz
    }
    this.modalRef = this.modalService.show(modalTemplateRef, config)
  }

  closeModal(close?: boolean) {
    this.modalService.hide(this.modalRef.id)
    document.body.classList.remove('modal-open')
  }

  async submit(isDraft: boolean = false) {
    try {
      this.loadingInProgress = true

      //when user saves as draft directly from question page
      if (isDraft && this.activeQuestion instanceof QuestionDataTableFe) {
        this.saveCurrentTableData(this.activeQuestion)
      }

      // TODO timelineItems are empty, they updated on the fly in stateservice
      // let createReqActivity = this.task.timelineItems.find((a) => a.type == ActivityTypeFe.CREATE_REQUEST)
      let answeredQuestions = this.questions.filter((q) => this.isQuesVisible(q))
      // let answeredQuestions = this.questions;
      let answers = answeredQuestions.map((q) => q.newAnswer)
      for (let a of answers) {
        if (a instanceof AnswerDataTable_AddFe && !a.dataGridService.isDataValid) {
          this.alertService.showError(this.ErrorsFe.INVALID_VALUE_IN_DATA_TABLE.message)
          this.loadingInProgress = false
          return
        }
      }

      for await (let a of answers) {
        if (a instanceof AnswerDataTable_AddFe) {
          let question = this.task.questionnaire.getQuestionFromAnswer(a)
          if (a.dataStorageId && !a.isOpenedOnce) {
            continue
          }
          let updatedTable = a.dataGridService.table
          const tableFile = this.tableStorageService.convertTableIntoFile(updatedTable)
          const formData = new FormData()
          formData.append('files', tableFile.blob, `${tableFile.id}`)
          formData.append('requestId', `${this.task.id}`)
          formData.append('requestGroupId', `${this.task.requestGroupId}`)
          formData.append('questionId', `${a.questionId}`)
          formData.append('label', `${question.question}`)
          formData.append('questionType', QUESTION_TYPE.DATA_TABLE)

          await this.stateService.uploadDataTableFile(this.task.managementCompanyId, formData)
          a.dataStorageId = tableFile.id
          a.managementCompanyId = this.task.managementCompanyId
          a.table = updatedTable.toDataGridTableData()
          a.draftTable = updatedTable
        }
      }

      answers = answers.map((a) => a.toTransfer())
      let activity = new SubmitAnswersActivity_AddFe(
        IdUtil.next(),
        this.message,
        isDraft,
        this.task.id,
        this.task.requestGroupId,
        this.task.title,
        this.task.managementCompanyId,
        answers
      )
      if (this.answerActivity && this.answerActivity.draft) {
        activity.id = this.answerActivity.id
        this.answerActivity = new SubmitAnswersActivityFe(
          this.answerActivity.id,
          this.answerActivity.submitterAffId,
          this.answerActivity.submitterFirstName,
          this.answerActivity.submitterLastName,
          this.answerActivity.submitterCompanyId,
          this.answerActivity.submitterCompanyName,
          new Date(),
          activity.message,
          activity.draft,
          READ_STATUS.READ,
          activity.requestTaskId,
          activity.requestGroupId,
          activity.requestGroupLabel,
          activity.answers.map((a) => AnswerTypeConversionFe.fromTransfer(a)) as AnswerFe[],
          this.languageService
        )
        await this.stateService.updateDraftAnswer(activity, this.answerActivity)
      } else {
        let addedActivity = await this.stateService.addTaskTimelineItem(activity, this.task)
        this.submitted = isDraft ? false : true
      }
      if (!activity.draft) {
        this.deleteData()
      }
      this.loadingInProgress = false
    } catch (error) {
      this.loadingInProgress = false
    }
    this.close()
  }

  close() {
    this.closeModal()
    this.endAnswer()
  }

  endAnswer() {
    this.viewNo = 1
    this.endAnswerMode.next(true)
  }

  deleteData() {
    this.fileToUpload = null
    this.uploadedAttachmentFile = null
    this.selectedFileToBeOpen = null
    this.uploadProgress = null
    this.fileUploadMessages = null
  }

  selectedFile: FileDataFe

  fileSelected(file: FileDataFe) {
    this.selectedFile = file
  }

  async dataExtracted(newTableData: TableDataFe) {
    newTableData.rows.forEach((row) => (row.status = RowStatusFe.ADDED))
    const newRows = []
    let service = (this.activeQuestion as QuestionDataTableFe).newAnswer.dataGridService
    service.updateTableData()
    service.table.rows.forEach((row) => newRows.push(RowFe.fromDataGridRow(row)))
    service.table.rows = newRows.concat(newTableData.rows)
    service.table.rowCount = service.table.rows.length
    let isValid = service.observer.checkDataValidity()
    service.markDataModified(newTableData.rows.length > 0, isValid)
    await service.observer.renderNewDataTable()
    this.closeFileExtractor()
    this.deleteData()
  }

  toggleTips() {
    this.displayService.toggleTips()
  }

  closeAttachments() {
    this.displayService.closeAttachments()
  }

  public fileToUpload: any
  public uploadedAttachmentFile: FileDataFe
  public selectedFileToBeOpen: FileDataFe
  public uploadProgress: number
  public fileUploadMessages: string
  public isDisabled: boolean = true
  isUploading: boolean = false

  async openFile(answer: AnswerFe | Answer_AddFe) {
    if (answer instanceof AnswerAttachmentFe || answer instanceof AnswerAttachment_AddFe) {
      answer.isLoading = true
      if (!answer.file) {
        let name = answer.attachmentLabel
        let file = await this.downloadFile(answer.attachmentStorageId, name)
        answer.file = file
      }
      let date = this.answerActivity ? this.answerActivity.submissionDate : new Date()
      answer.isLoading = false
      this.openAttachment(answer.id, null, answer.file, date)
    }
  }

  async downloadFile(fileId: string, fileName: string): Promise<FileDataFe> {
    let file = FileDataFe.fromBlob(fileName)
    file.loadingFileInProgress = true
    file.id = fileId
    let content = await this.stateService.downloadAttachmentFile(this.task.managementCompanyId, file.id)
    file.addBlobContent(content, this.domSanitizer)
    file.type = FileTypeFe.fromName(fileId)
    file.loadingFileInProgress = false
    return file
  }

  public selectFilesForUpload(event, fileViewer: TemplateRef<any>): void {
    Array.from(event.target.files).forEach((file) => {
      this.fileToUpload = file
      this.uploadProgress = 0
    })
    ;(document.getElementById('uploadCaptureInputFile') as HTMLInputElement).value = ''
    this.enableButton()
    if (!this.isUploading && !this.isDisabled) {
      this.openFileViewer(fileViewer)
    }
  }

  public deleteFileFromUpload(): void {
    this.fileToUpload = null
  }

  public enableButton() {
    if (this.fileToUpload) {
      this.isDisabled = false
    } else {
      this.isDisabled = true
    }
  }

  public async uploadFiles() {
    this.loadingInProgress = true

    const file = this.fileToUpload
    let newFile = FileDataFe.fromBlob(file.name)
    newFile.type = FileTypeFe.fromName(newFile.label)
    newFile.id = IdUtil.next()
    let extension = newFile.type.extension
    const formData = new FormData()
    formData.append('files', file, `${newFile.id}.${extension}`)
    formData.append('requestId', `${this.task.id}`)
    formData.append('requestGroupId', `${this.task.requestGroupId}`)
    formData.append('questionId', `${this.activeQuestion.id}`)
    formData.append('label', `${newFile.label}`)
    formData.append('questionType', QUESTION_TYPE.ATTACHMENT)
    let isUploaded = await this.upload(formData)

    this.uploadedAttachmentFile = newFile
    this.fileToUpload = null
    this.fileUploadMessages = null
    this.uploadProgress = null

    if (this.activeQuestion instanceof QuestionAttachmentFe) {
      this.activeQuestion.newAnswer.managementCompanyId = this.task.managementCompanyId
      this.activeQuestion.newAnswer.attachmentLabel = newFile.label
      this.activeQuestion.newAnswer.attachmentStorageId = `${this.uploadedAttachmentFile.id}.${this.uploadedAttachmentFile.type.extension}`
    }

    this.loadingInProgress = false
    this.modalRef.hide()
    document.body.classList.remove('modal-open')
  }

  public async loadFile(file: FileDataFe) {
    file.loadingFileInProgress = true
    let content = await this.stateService.downloadAttachmentFile(this.task.managementCompanyId, file.id)
    file.addBlobContent(content, this.domSanitizer)
    file.loadingFileInProgress = false
  }

  public async upload(formData) {
    this.uploadProgress = 30
    try {
      let data = await this.stateService.uploadAttachmentFile(this.task.managementCompanyId, formData)
      this.uploadProgress += data.progress
      this.uploadProgress += 20
      this.fileUploadMessages = data.info
    } catch (error) {
      let file = this.fileToUpload
      this.alertService.showError(`File ${file} could not be uploaded!`)
    }
  }

  public formatBytes(bytes) {
    if (bytes === 0) {
      return '0 Bytes'
    }
    const k = 1024
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed()) + ' ' + sizes[i]
  }

  public openAttachmentFile(modalRef: TemplateRef<any>, index: number): void {
    let file = this.uploadedAttachmentFile
    file.setMainViewer()
    this.selectedFileToBeOpen = file
    this.openModal(modalRef, 'modal-md')
  }

  async openFileViewer(modal: TemplateRef<any>): Promise<void> {
    this.loadingInProgress = true
    const file = this.fileToUpload
    await this.readData(file, modal)
  }

  async readData(file: any, modal: TemplateRef<any>) {
    var reader = new FileReader()
    reader.onload = async (e: any) => {
      let blob = new Blob([e.target.result], { type: file.type })
      let newFile = FileDataFe.fromBlob(file.name)
      newFile.type = FileTypeFe.fromName(newFile.label)
      newFile.id = IdUtil.next()
      newFile.contentType = file.type
      newFile.addBlobContent(blob, this.domSanitizer)
      this.selectedFile = newFile
      this.loadingInProgress = false
      this.modalRef.hide()
      document.body.classList.remove('modal-open')
      this.openModal(modal, 'modal-md')
    }
    reader.readAsArrayBuffer(file)
  }

  async openTableFile(answer: AnswerDataTable_AddFe | AnswerDataTableFe, question?: QuestionDataTableFe) {
    if (this.viewNo == 2) {
      this.openAttachment(answer.id, answer.dataGridService, null, new Date())
    } else if (answer instanceof AnswerDataTableFe) {
      answer.isLoading = true
      let service = new RequestEntryDataGridIntegrationService(new TableDataFe())
      if (!answer.dataTable) {
        let content = await this.stateService.downloadDataTableFile(this.task.managementCompanyId, answer.dataStorageId)
        service.mode = 'view'
        await this.tableStorageService.convertFileIntoTable(content, (table: TableDataFe) => {
          service.table.key = answer.id
          service.table.rows = table.rows
          service.table.rowCount = table.rows.length
          service.table.dataSchema = table.dataSchema
          answer['dataTable'] = table
          answer['dataGridService'] = service
          service.observer?.renderNewDataTable()
          this.openAttachment(answer.id, service, null, this.answerActivity.submissionDate)
        })
      } else {
        this.openAttachment(answer.id, answer.dataGridService, null, this.answerActivity.submissionDate)
      }
      answer.isLoading = false
    } else if (answer instanceof AnswerDataTable_AddFe) {
      answer.isLoading = true
      if (this.answerActivity && answer.dataStorageId && !answer.isOpenedOnce) {
        await this.setTableDraft(question)
      }
      answer.dataGridService.mode = DataGridTableMode.VIEW
      this.openAttachment(answer.id, answer.dataGridService, null, new Date())
      answer.isLoading = false
    }
  }

  closeFileUploader() {
    this.deleteData()
    this.closeModal(true)
  }

  openAttachment(
    id: string,
    dataGridService: DataGridServiceInterface | null,
    file: FileDataFe | null,
    submissionDate: Date
  ) {
    let attachment = new AttachmentFe(id, dataGridService, file, submissionDate)
    if (dataGridService) {
      attachment.isTable = true
      attachment.isFile = false
    } else {
      attachment.isFile = true
      attachment.isTable = false
    }
    this.displayService.openAttachment(attachment)
  }

  // Questionnaire functionality
  showMenu(event: any) {
    let id = event.currentTarget.id
    let idNumber = id.split('-')[1]
    let menuBtn = document.getElementById(id)
    let menu = menuBtn.nextElementSibling
    let chevron = menuBtn.children[2]
    menu.classList.toggle('show')
    if (chevron.classList.contains('la-angle-down')) {
      chevron.classList.remove('la-angle-down')
      chevron.classList.add('la-angle-up')
    } else {
      chevron.classList.remove('la-angle-up')
      chevron.classList.add('la-angle-down')
    }
  }

  saveCurrentTableData(ques: QuestionDataTableFe) {
    ques.newAnswer.isOpenedOnce = true
    if (ques.newAnswer.dataGridService.observer) {
      ques.newAnswer.dataGridService.updateTableData()
    }
  }

  prevQuestion() {
    if (this.activeQuestion instanceof QuestionDataTableFe) {
      this.saveCurrentTableData(this.activeQuestion)
    }
    this.activeQuestionIndex = this.questionIdToIndexMap.get(this.activeQuestion.id)

    let index = this.activeQuestionIndex - 1
    if (this.viewNo == 3) {
      this.viewNo = 2
      index = this.activeQuestionIndex
    }
    let question = this.questions[index]
    while (!this.isQuesVisible(question)) {
      question = this.questions[--index]
    }
    this.activeQuestionIndex = index
    this.activeQuestion = this.questions[this.activeQuestionIndex]
    this.progress = ((this.activeQuestionIndex + 1) / this.questions.length) * 100
  }

  nextQuestion() {
    if (this.inputNumberHasError || this.isInvalidDateFromState || this.isInvalidDateToState) return // if error prevent next question
    this.activeQuestion.newAnswer.isSubmitted = true
    if (this.activeQuestion instanceof QuestionDataTableFe) {
      this.saveCurrentTableData(this.activeQuestion)
    }

    if (this.activeQuestion.newAnswer.isInvalid(this.activeQuestion.required)) return

    let index = this.questionIdToIndexMap.get(this.activeQuestion.id)
    this.activeQuestionIndex = index

    let nextQuestion = this.questions[++index]
    let values = this.getAnswerValues()
    while (nextQuestion && nextQuestion.followUpCondition) {
      if (nextQuestion.followUpCondition.evaluate(values)) {
        break
      } else {
        nextQuestion = this.questions[++index]
        if (!nextQuestion) {
          this.progress = 100
          this.startReview()
          this.isEveryQuestionAnswered()
          return
        }
      }
    }
    if (!nextQuestion) {
      this.startReview()
      this.progress = 100
      this.isEveryQuestionAnswered()
      return
    } else {
      this.activeQuestionIndex = index
      this.activeQuestion = nextQuestion
      this.progress = ((this.activeQuestionIndex + 1) / this.questions.length) * 100
    }

    if (
      this.answerActivity &&
      nextQuestion instanceof QuestionDataTableFe &&
      nextQuestion.newAnswer.dataStorageId &&
      !nextQuestion.newAnswer.isOpenedOnce
    ) {
      this.setTableDraft(nextQuestion)
    }
    if (nextQuestion.newAnswer instanceof AnswerDataTable_AddFe && nextQuestion.newAnswer.dataGridService) {
      nextQuestion.newAnswer.dataGridService.mode = DataGridTableMode.EDIT_TABLE_DATA
    }
  }

  getAnswerValues(): Map<string, any> {
    let answerValues: Map<string, any> = new Map()
    if (this.viewNo != 1) {
      this.questions.forEach((q) => {
        if (q instanceof QuestionMultipleChoiceFe && q.newAnswer.selectedOptionIds) {
          q.newAnswer.selectedOptionIds.forEach((optionId) => {
            let ref = `${q.id}.${optionId}`
            answerValues.set(ref, true)
          })
        } else if (q instanceof QuestionSingleChoiceFe && q.newAnswer.selectedOptionId) {
          let ref = `${q.id}.${q.newAnswer.selectedOptionId}`
          answerValues.set(ref, true)
        }
      })
    } else {
      if (this.answerActivity) {
        this.questions.forEach((q) => {
          if (q instanceof QuestionMultipleChoiceFe && q.answer && q.answer.selectedOptionIds) {
            q.answer.selectedOptionIds.forEach((optionId) => {
              let ref = `${q.id}.${optionId}`
              answerValues.set(ref, true)
            })
          } else if (q instanceof QuestionSingleChoiceFe && q.answer && q.answer.selectedOptionId) {
            let ref = `${q.id}.${q.answer.selectedOptionId}`
            answerValues.set(ref, true)
          }
        })
      }
    }

    return answerValues
  }

  dateToString(date: any) {
    date = new Date(date)
    return DateUtil.toString3(date)
  }

  deleteUploadedAttachment() {
    ;(this.activeQuestion as QuestionAttachmentFe).newAnswer.file = null
    ;(this.activeQuestion as QuestionAttachmentFe).newAnswer.attachmentStorageId = null
    this.uploadedAttachmentFile = null
  }

  addOption(optionId: string, event: Event) {
    if (this.activeQuestion.newAnswer instanceof AnswerMultipleChoice_AddFe) {
      this.activeQuestion.newAnswer.addOption(optionId, event)
    }
  }

  startReview() {
    if (this.activeQuestion instanceof QuestionDataTableFe) {
      this.saveCurrentTableData(this.activeQuestion)
    }
    this.activeQuestion.newAnswer.isSubmitted = true
    if (this.activeQuestion.required && this.activeQuestion.newAnswer.isInvalid(true)) {
      return
    }
    this.viewNo = 3
    this.isReviewedOnce = true
    this.progress = 100
  }

  editQuestion(question: QuestionFe, index: number) {
    this.activeQuestion = question
    this.activeQuestionIndex = this.questionIdToIndexMap.get(question.id)
    this.viewNo = 2

    if (
      this.answerActivity &&
      question instanceof QuestionDataTableFe &&
      question.newAnswer.dataStorageId &&
      !question.newAnswer.isOpenedOnce
    ) {
      this.setTableDraft(question)
    }

    if (
      this.activeQuestion.newAnswer instanceof AnswerDataTable_AddFe &&
      this.activeQuestion.newAnswer.dataGridService
    ) {
      this.activeQuestion.newAnswer.dataGridService.mode = DataGridTableMode.EDIT_TABLE_DATA
    }
  }

  isQuesVisible(ques: QuestionFe) {
    if (ques.followUpCondition) {
      let values = this.getAnswerValues()
      return ques.followUpCondition.evaluate(values)
    }
    return true
  }

  isOptionSelected(optionId: string) {
    if (this.activeQuestion instanceof QuestionMultipleChoiceFe && this.activeQuestion.newAnswer.selectedOptionIds) {
      return this.activeQuestion.newAnswer.selectedOptionIds.some((id) => id == optionId)
    }
  }

  autoGrowTextZone(e) {
    e.target.style.height = '0px'
    e.target.style.height = (e.target.scrollHeight < 575 ? e.target.scrollHeight + 25 : 600) + 'px'
  }

  setDateTo(date: string) {
    if (this.activeQuestion instanceof QuestionDateFe) {
      this.activeQuestion.newAnswer.setDateTo(date)
    }
  }

  setDateFrom(date: string) {
    if (this.activeQuestion instanceof QuestionDateFe) {
      this.activeQuestion.newAnswer.setDateFrom(date)
    }
  }

  resetNewAnswers() {
    this.questions.forEach((q) => q.newAnswer.reset())
  }

  cancelSubmission() {
    this.closeModal(true)
    this.resetNewAnswers()
    this.deleteData()
    this.close()
  }

  closeFileExtractor() {
    this.isFileExtractor = false
    this.activeQuestion.newAnswer['dataGridService'].mode = DataGridTableMode.EDIT_TABLE_DATA
  }

  openFileExtractor() {
    this.toCopyTableService = new TaskDataGridIntegrationService(
      (this.activeQuestion.newAnswer as AnswerDataTable_AddFe).dataGridService.table
    )
    this.toCopyTableService.mode = DataGridTableMode.VIEW
    this.isFileExtractor = true
  }

  handleToolbarAction(actionName: string) {
    switch (actionName) {
      case 'toggle_quick_tips':
        this.toggleTips()
        break
    }
  }

  isReuseAnswers() {
    if (this.task.requestGroup.isChildRecurring) {
      return this.recurringAnsweredTasks().length > 0
    }
    return false
  }

  recurringAnsweredTasks(): TaskFe[] {
    let tasks = []
    let task = this.task
    while (task && task.requestGroup.isChildRecurring) {
      let prevTask = this.tasks.find((t) => t.requestGroupId == task.requestGroup.recurringParent)
      if (prevTask && prevTask.status != REQUEST_TASK_STATUS.CREATED) {
        tasks.push(prevTask)
      }
      task = prevTask
    }
    return tasks
  }

  async startAnswering(question: QuestionFe, index) {
    this.activeQuestion = question
    this.activeQuestionIndex = this.questionIdToIndexMap.get(question.id)
    this.viewNo = 2
    this.progress = ((this.activeQuestionIndex + 1) / this.questions.length) * 100
    this.answerModeStarted.next(true)
    if (this.answerActivity) {
      this.questions.forEach((q) => {
        if (q instanceof QuestionDataTableFe) {
          q.newAnswer.isOpenedOnce = false
          if (q.newAnswer.dataGridService) {
            q.newAnswer.dataGridService.mode = DataGridTableMode.EDIT_TABLE_DATA
          }
        }
      })
      if (question instanceof QuestionDataTableFe) {
        await this.setTableDraft(question)
      }
    }
  }

  showAnswer(task) {
    this.selectedPrevTask = task
    this.answerActivityOfPrevTask = this.selectedPrevTask.timelineItems
      .slice()
      .reverse()
      .find((item) => item instanceof SubmitAnswersActivityFe) as SubmitAnswersActivityFe
    this.selectedPrevTask.questionnaire.setAnswers(this.answerActivityOfPrevTask)
    this.isSecondView = true
  }

  async useAnswers() {
    this.loadingInProgress = true
    this.task.questionnaire.setNewAnswers(this.answerActivityOfPrevTask, this.task.managementCompanyId)
    this.questions.forEach(async (q) => {
      if (q instanceof QuestionDataTableFe) {
        q.newAnswer.draftTable = null
        await this.setTableDraft(q)
        if (q.newAnswer instanceof AnswerDataTable_AddFe && q.newAnswer.dataGridService) {
          q.newAnswer.dataGridService.mode = DataGridTableMode.EDIT_TABLE_DATA
        }
        q.newAnswer.isOpenedOnce = false
      }
    })

    this.closeModal()
    this.loadingInProgress = false
  }

  openMessageModal() {
    this.isChatOpen = !this.isChatOpen
  }

  hasAnyAnswers() {
    let anyQuest = this.questions.find((q) => q.newAnswer.hasAnyValue())
    return anyQuest && (this.answerActivity ? true : anyQuest != this.activeQuestion)
  }

  getUnitsByMeasurementType() {
    // If any logic is needed to filter the unit list, we put it here
    return this.unitsByMeasurementType
  }

  getCustomUnits() {
    // If any logic is needed to filter the unit list, we put it here
    return this.customUnits
  }

  getMeasurementType() {
    let measurementType = new Set()
    if (this.activeQuestion instanceof QuestionNumberFe && this.activeQuestion.measurementKey) {
      measurementType.add(this.activeQuestion.measurementKey)
    }
    return measurementType
  }

  alignDropdown(event) {
    var elDistanceToTop = window.scrollY + event.target.getBoundingClientRect().top
    var elDistanceToLeft = window.scrollX + event.target.getBoundingClientRect().left
    let unitDropdown = document.querySelector('.dropdown-menu.answerComponent.show')
    if (unitDropdown && unitDropdown[0]) {
      unitDropdown[0].top = elDistanceToTop
      unitDropdown[0].left = elDistanceToLeft
    }
  }

  filterNumberInput(event: KeyboardEvent): void {
    if (!InputFilterUtil.isValidNumberInput(event)) {
      event.preventDefault()
      this.inputNumberHasError = true
    } else {
      this.inputNumberHasError = false
    }

    this.updateNumberAnswer(this.activeQuestion.newAnswer['value'])
  }

  updateNumberAnswer(newValue: string): void {
    const isValidNumber = /^(-?\d*\.?\d*)?$/.test(newValue)

    if (isValidNumber) {
      this.inputNumberHasError = false
      this.activeQuestion.newAnswer['value'] = parseFloat(newValue)
    } else {
      this.inputNumberHasError = true
    }
  }

  containsPredefinedOptions(): boolean {
    const predefinedOptions = (this.activeQuestion as any)?.predefinedOptions
    return predefinedOptions && predefinedOptions.length > 0
  }

  getPredefineAnswers(questionType: string): any[] {
    if (questionType === 'date' || questionType === 'dateRange') {
      return (this.activeQuestion as QuestionDateFe).predefinedOptions
    }
    return []
  }

  isUnitOnly(): boolean {
    const check = (this.activeQuestion as QuestionNumberFe)?.isUnitOnly()
    if (check) {
      this.activeQuestion.newAnswer['unit'] = this.activeQuestion['predefinedOptions'][0].unit
    }
    return check
  }

  setDateAnswer(option: PredefinedDateOptionFe, isRange: boolean) {
    const format = 'yyyy-MM-dd'
    const formattedFrom = this.datePipe.transform(option.from, format)
    this.setDateFrom(formattedFrom)

    if (isRange) {
      const formattedTo = this.datePipe.transform(option.to, format)
      this.setDateTo(formattedTo)
      this.activeQuestion.selectedPredefineAnswer = `${formattedFrom} ${this.locale('locale_key.pages.data_request.create_question.date.options.range.to')} ${formattedTo}`
    } else {
      this.activeQuestion.selectedPredefineAnswer = formattedFrom
    }

    this.closePredefinedDropdown()
  }

  setNumberAnswer(filter: string, option: PredefinedNumberOptionFe) {
    this.activeQuestion.newAnswer['unit'] = option.unit
    this.activeQuestion.newAnswer['measurementKey'] = option.measurementKey
    this.activeQuestion.newAnswer['value'] = option.value

    if (filter === UNIT) {
      this.activeQuestion.selectedPredefineAnswer = `${option.unit}`
    } else if (filter === UNIT_VALUE) {
      this.activeQuestion.selectedPredefineAnswer = `${option.value} ${this.getUnitString(option.unit)}`
    }

    this.closePredefinedDropdown()
  }

  setUnitForPredefinedAnswer(event: UnitFe) {
    const question = this.getNumberQuestion()
    if (question) {
      if (!question.tempPredefinedOption) {
        question.tempPredefinedOption = new PredefinedNumberOptionFe()
      }
      question.tempPredefinedOption.unit = event.symbol
      question.tempPredefinedOption.measurementKey = event.measurementType
    }
  }

  setUnitForAnswer(event: UnitFe) {
    const question = this.getNumberQuestion()
    if (question) {
      question.newAnswer['unit'] = event.symbol
      question['unit'] = event.symbol
      question['measurementKey'] = event.measurementType
    }
  }

  setEmissionFactorAnswerFromEF(ef: AbstractEmissionFactorFe) {
    this.activeQuestion.newAnswer['selectedEmissionFactor'] = ef
  }

  setEmissionFactorAnswerOptionFromEF(ef: AbstractEmissionFactorFe) {
    const option = new PredefinedEmissionFactorOptionFe(ef)
    this.activeQuestion['predefinedOptions'].push(option)
    this.recentlyAddedCustomOptions.push(option)

    this.setEmissionFactorAnswer(option)
  }

  setEmissionFactorAnswer(option: PredefinedEmissionFactorOptionFe) {
    this.activeQuestion.newAnswer['selectedEmissionFactor'] = option.selectedEmissionFactor
    this.activeQuestion.selectedPredefineAnswer = option.selectedEmissionFactor

    this.closePredefinedDropdown()
  }

  closePredefinedDropdown() {
    const dropdownElements = document.querySelectorAll('.predefinedDropdown')
    dropdownElements.forEach((dropdown: any) => {
      dropdown.classList.remove('show')
    })
  }

  isRangeDateQuestion(): boolean {
    return !!(this.activeQuestion as QuestionDateFe).range
  }

  addCustomPredefinedNumberOption(numberElementFrom: HTMLInputElement): void {
    const value = numberElementFrom.valueAsNumber
    const question = this.getNumberQuestion()

    let option
    if (!isNaN(value)) {
      option = new PredefinedNumberOptionFe(
        question.tempPredefinedOption.unit,
        question.tempPredefinedOption.measurementKey,
        value
      )

      if (this.isValueAndUnit()) {
        if (
          question.tempPredefinedOption &&
          !question.tempPredefinedOption.unit &&
          !question.tempPredefinedOption.measurementKey &&
          question.tempPredefinedOption.measurementKey !== NoUnit.MEASUREMENT_KEY
        )
          return

        question.predefinedOptions.push(option)
        this.setNumberAnswer('unit-value', option)
      } else {
        question.predefinedOptions.push(option)
        this.setNumberAnswer('value', option)
      }

      question.tempPredefinedOption = undefined
      this.recentlyAddedCustomOptions.push(option)
    }

    numberElementFrom.value = ''
    question.isCustomOptionAdded = false
  }

  getNumberQuestion(): QuestionNumberFe {
    if (this.activeQuestion instanceof QuestionNumberFe) {
      return this.activeQuestion as QuestionNumberFe
    }

    return undefined
  }

  isValueAndUnit() {
    return (
      this.activeQuestion['predefinedOptions'] &&
      this.activeQuestion['predefinedOptions'].length > 0 &&
      this.activeQuestion['predefinedOptions'][0].value != null &&
      this.activeQuestion['predefinedOptions'][0].value >= 0
    )
  }

  isInvalidDateFrom(from: HTMLInputElement | Date): boolean {
    this.isInvalidDateFromState = DateUtil.isInvalidDateFrom(from)
    return this.isInvalidDateFromState
  }

  isInvalidDateTo(from: HTMLInputElement | Date, to: HTMLInputElement | Date): boolean {
    this.isInvalidDateToState = DateUtil.isInvalidDateTo(from, to)
    return this.isInvalidDateToState
  }

  getInvalidYearTooltipFrom(dateInputElementFrom: HTMLInputElement): string {
    return !DateUtil.isValidYear(dateInputElementFrom?.valueAsDate)
      ? this.locale('locale_key.pages.data_request.edit.invalid_year')
      : ''
  }

  getInvalidYearTooltipRange(dateInputFrom: HTMLInputElement | Date, dateInputTo: HTMLInputElement | Date): string {
    const fromDate = dateInputFrom instanceof HTMLInputElement ? dateInputFrom.valueAsDate : dateInputFrom
    const toDate = dateInputTo instanceof HTMLInputElement ? dateInputTo.valueAsDate : dateInputTo

    if (fromDate && toDate) {
      if (!DateUtil.isValidYear(toDate)) {
        return this.locale('locale_key.pages.data_request.edit.invalid_year')
      }
      if (fromDate > toDate) {
        return this.locale('locale_key.pages.data_request.edit.to_date_must_be_on_or_after_from_date')
      }
    }

    return ''
  }

  addCustomPredefinedDateOption(
    question: QuestionFe,
    isDateRange: boolean,
    dateInputElementFrom: HTMLInputElement,
    dateInputElementTo: HTMLInputElement = undefined
  ) {
    if (this.isInvalidDateFromState || this.isInvalidDateToState) {
      return
    }

    this.isInvalidDateFromState = false
    this.isInvalidDateToState = false
    const fromDate = dateInputElementFrom.valueAsDate
    const toDate = dateInputElementTo?.valueAsDate
    const questionDate = question as QuestionDateFe

    let option
    if (!isDateRange && fromDate) {
      option = new PredefinedDateOptionFe(fromDate)
    } else if (isDateRange && toDate) {
      option = new PredefinedDateOptionFe(fromDate, toDate)
    }
    this.recentlyAddedCustomOptions.push(option)
    questionDate.predefinedOptions.push(option)

    this.setDateAnswer(option, isDateRange)
    dateInputElementFrom.value = ''
    question.isCustomOptionAdded = false
  }

  getMinDate(dateInput: HTMLInputElement | Date): string {
    return dateInput instanceof HTMLInputElement ? dateInput.value : DateUtil.getMinDate(dateInput)
  }

  getSortedPredfinedNumberOptions(question: QuestionFe): PredefinedNumberOptionFe[] {
    return (question as QuestionNumberFe).getSortedPredfinedNumberOptions()
  }

  getSortedPredfinedDateOptions(question: QuestionFe): PredefinedDateOptionFe[] {
    return (question as QuestionDateFe).getSortedPredfinedDateOptions()
  }

  showEmissionFactorModal() {
    this.showEF = true
  }

  getSelectedAnswerOfDateQuestion() {
    if (this.activeQuestion.newAnswer && this.activeQuestion.newAnswer['dateFrom']) {
      const from = this.activeQuestion.newAnswer['dateFrom']
      const dateFormat = 'dd MMM, yyyy'

      if (this.activeQuestion.newAnswer['range']) {
        const to = this.activeQuestion.newAnswer['dateTo']
        return `${this.datePipe.transform(from, dateFormat)} ${this.locale('locale_key.pages.data_request.create_question.date.options.range.to')} ${this.datePipe.transform(to, dateFormat)}`
      }
      return `${this.datePipe.transform(from, dateFormat)}`
    }

    return (
      this.activeQuestion.selectedPredefineAnswer ||
      this.locale('locale_key.pages.data_request.create.follow_up_condition.placeholder')
    )
  }

  getSelectedAnswerOfNumberQuestion() {
    if (
      this.activeQuestion.newAnswer &&
      this.activeQuestion.newAnswer['value'] != null &&
      (this.activeQuestion.newAnswer['unit'] != null ||
        this.activeQuestion.newAnswer['measurementKey'] === NoUnit.MEASUREMENT_KEY ||
        !this.activeQuestion.newAnswer['measurementKey'])
    ) {
      return `${this.activeQuestion.newAnswer['value']} ${this.getUnitString(this.activeQuestion.newAnswer['unit'])}`
    }

    return (
      this.activeQuestion.selectedPredefineAnswer ||
      this.locale('locale_key.pages.data_request.create.follow_up_condition.placeholder')
    )
  }

  getSelectedEmissionFactor() {
    if (this.activeQuestion.newAnswer['selectedEmissionFactor']) {
      return this.activeQuestion.newAnswer['selectedEmissionFactor']
    }
    return this.activeQuestion.selectedPredefineAnswer
  }

  showSelectPlaceHolder(unit, measurementKey) {
    if (measurementKey === NoUnit.MEASUREMENT_KEY) return false
    if (!unit) return true
    return false
  }

  getUnitString(unit) {
    return UnitUtilFe.getUnitString(unit)
  }

  getSelectedUnitContainer(symbol: string) {
    let unit = this.units.find((u) => u.symbol === symbol)
    this.selectedUnitContainer.selectedUnit = unit
    return this.selectedUnitContainer
  }

  skipInitNoUnit() {
    return (
      this.activeQuestion['measurementKey'] !== NoUnit.MEASUREMENT_KEY ||
      (this.activeQuestion['measurementKey'] === NoUnit.MEASUREMENT_KEY && !this.activeQuestion.answer)
    )
  }

  expandedState: { [key: string]: boolean } = {}
  onIconClick() {
    if (this.task && this.task.questionnaire && this.task.questionnaire.sections.length > 0) {
      const firstQuestion = this.task.questionnaire.sections[0].questions[0]
      this.startAnswering(firstQuestion, 0)
    } else {
      this.openQuestionnaire.emit()
    }
  }

  setFormView() {
    this.isFormView = true
  }

  setTableView() {
    this.isFormView = false
  }

  // rowCount: number = 1;
  selectedEntryIndex: number = 0

  // updateRowCount(count: number) {
  //   this.rowCount = count;
  // }

  updateSelectedEntry(event: Event) {
    const selectElement = event.target as HTMLSelectElement
    this.selectedEntryIndex = parseInt(selectElement.value, 10)
  }

  showFollowUpQuestionsAnswerView(question: QuestionFe) {
    this.followupsExpandedInAnswerView[question.id] = true
  }

  hideFollowUpQuestionsAnswerView(question: QuestionFe) {
    this.followupsExpandedInAnswerView[question.id] = false
  }

  toggleFollowupQuestion(section: SectionFe, question: QuestionFe, event: Event) {
    // Don't prevent default action
    // event.preventDefault();
    // event.stopPropagation();

    // Update the state after a short delay to allow Bootstrap to toggle the accordion
    setTimeout(() => {
      this.followupsExpandedInPreview[question.id] = !this.followupsExpandedInPreview[question.id]
      if (this.followupsExpandedInPreview[question.id]) {
        this.showFollowupQuestions(section, question)
      } else {
        this.hideFollowupQuestions(section, question)
      }
    }, 0)
  }

  hideFollowupQuestions(section: SectionFe, question: QuestionFe) {
    this.followupsExpandedInPreview[question.id] = false
    this.collapseAndHideChildrenSbtrees(section, question)
  }

  showFollowupQuestions(section: SectionFe, question: QuestionFe) {
    this.followupsExpandedInPreview[question.id] = true
    this.showChildrenCollapsed(section, question)
  }

  collapseAndHideChildrenSbtrees(section: SectionFe, question: QuestionFe) {
    const children = section.getChildrenOfParent(question)
    children.forEach((child) => {
      this.collapseAndHideSubtree(section, child)
    })
  }

  showChildrenCollapsed(section: SectionFe, question: QuestionFe) {
    const children = section.getChildrenOfParent(question)
    children.forEach((child) => {
      this.followupsExpandedInPreview[child.id] = false
    })
  }

  collapseAndHideSubtree(section: SectionFe, question: QuestionFe) {
    const children = section.getChildrenOfParent(question)
    children.forEach((child) => {
      this.followupsExpandedInPreview[child.id] = false
      this.collapseAndHideSubtree(section, child)
    })
    this.followupsExpandedInPreview[question.id] = false
  }

  hasFollowUpQuestions(section: SectionFe, question: QuestionFe): boolean {
    return section.getChildrenOfParent(question).length > 0
  }

  getIndentationStyle(level: number): { [key: string]: string } {
    let paddingLeft: number
    if (level === 1) {
      paddingLeft = 14
    } else if (level >= 2 && level <= 5) {
      paddingLeft = 14 + (level - 1) * 10
    } else {
      paddingLeft = 14 + 4 * 10
    }

    return {
      border: 'none !important',
      'padding-left': `${paddingLeft}px`,
      'margin-bottom': '0'
    }
  }

  openEfDetailTab(selectedEf?: any) {
    const ef = selectedEf ? selectedEf : this.getSelectedEmissionFactor()
    this.displayService.openEfDetailsTab(ef)
  }

  getSubmittedNumberAnswer(question: any) {
    let unitString = question.newAnswer.unit
    if (question.newAnswer.unit == null) {
      unitString = this.locale('locale_key.pages.emission.no_unit')
    }
    return question.newAnswer.value + ' ' + unitString
  }

  setDatePlaceholder(): void {
    const userLocale = navigator.language || 'en-US'
    const exampleDate = new Date(2023, 8, 28)

    const localeDateString = this.datePipe.transform(exampleDate, 'shortDate', undefined, userLocale)

    if (localeDateString) {
      this.datePlaceholder = localeDateString.replace(/\d{2}/, 'DD').replace(/\d{2}/, 'MM').replace(/\d{4}/, 'YYYY')
    }
  }

  // ngAfterViewChecked() {
  //   this.scrollToBottom();
  // }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight
    } catch (err) {
      console.log('Scrolling failed:', err)
    }
  }
  onNewContentAdded() {
    this.scrollToBottom()
  }

  isEveryQuestionAnswered() {
    this.isQuestionnaireReadyForSubmission = this.questions.every((anyQuestion) => {
      if (anyQuestion.parentQuestionId) return true

      if (anyQuestion.newAnswer.isInvalid(anyQuestion.required)) return false

      const followUpQuestions = this.questions.filter((q) => q.parentQuestionId === anyQuestion.id)

      for (const followUpQuestion of followUpQuestions) {
        if (!followUpQuestion.followUpCondition && !followUpQuestion.newAnswer.isInvalid(followUpQuestion.required))
          continue

        if (!followUpQuestion.followUpCondition && followUpQuestion.newAnswer.isInvalid(followUpQuestion.required))
          return false

        const selectedOptionId = (anyQuestion.newAnswer as any).selectedOptionId
        const formulaString = followUpQuestion.followUpCondition.toFormulaString()

        // Handle QuestionSingleChoiceFe type
        const extractOptionId = (formula: string) => {
          const optionStartIndex = formula.indexOf('.')
          const optionEndIndex = formula.indexOf(' ', optionStartIndex)
          return formula.substring(optionStartIndex + 1, optionEndIndex)
        }

        const followUpQuestionOptionId = extractOptionId(formulaString)

        if (selectedOptionId !== followUpQuestionOptionId) continue

        if (followUpQuestion.newAnswer.isInvalid(followUpQuestion.required)) return false
      }

      return true
    })
  }

  getButtonTextStartOrResume(question: QuestionFe) {
    const startText = this.locale('locale_key.general.buttons.start')
    const resumeText = this.locale('locale_key.general.buttons.resume')

    if (question instanceof QuestionDataTableFe) {
      return question.newAnswer?.hasAnyValue() ? resumeText : startText
    }
    return question.answer?.hasAnyValue() ? resumeText : startText
  }

  //return xLarge and Large screen
  getMaxWidthStyle(): string {
    if (this.screenSize.isXLargeSize() || this.screenSize.isLargeSize()) {
      return 'max-width: 67vw !important'
    }
    return 'max-width: 100vw !important'
  }
}
