export enum DR_DATAPOINTS_PAGES {
  'VIEW' = 'VIEW',
  'EDIT' = 'EDIT'
}
export enum DR_TABS {
  'OVERVIEW' = 'OVERVIEW',
  'DATAPOINTS' = 'DATAPOINTS',
  'REQUESTS' = 'REQUESTS',
  'COLLECTED_DATA' = 'COLLECTED_DATA'
}
